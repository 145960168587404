<script setup lang="ts">
import { computed } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { addIcons } from './addIcons';

// MEMO: 現状 lazy-load には対応していないので、アイコンを追加する時は各コンポーネント内 or 共通で利用するものは以下で import しておく
addIcons();

const props = defineProps<{
  i?: string;
  icon?: string;
  fal?: boolean;
  fas?: boolean;
  far?: boolean;
  fak?: boolean;
}>();

const icon = computed<{ prefix: string; iconName: string } | string>(() => {
  if (props.icon) {
    return props.icon;
  }
  if (props.i) {
    let prefix = '';
    let iconName = props.i ?? '';
    if (props.fal) {
      prefix = 'fal';
    } else if (props.fas) {
      prefix = 'fas';
    } else if (props.far) {
      prefix = 'far';
    } else if (props.fak) {
      prefix = 'fak';
    }
    // i の先頭に fal-, fas-, far-, fak- で prefix を上書きできる仕様にする
    ['fal', 'fas', 'far', 'fak'].forEach(p => {
      if (iconName.startsWith(`${p}-`)) {
        iconName = iconName.slice(`${p}-`.length);
        prefix = p;
      }
    });

    return {
      prefix,
      iconName,
    };
  }
  return '';
});
</script>

<template>
  <FontAwesomeIcon :icon="icon" />
</template>
