import type { ShortcutPreference } from '@cdm/@generated-account-client/shared-types';
import type { GroupDefs, KeyDefs } from './types';

const SHORTCUT_CONTEXT = {
  Global: 'Global',
  Notebook: 'Notebook',
} as const;
export type ShortcutContext = typeof SHORTCUT_CONTEXT[keyof typeof SHORTCUT_CONTEXT];

export const getPreference = (
  key: string,
  modifiers: ShortcutPreference['modifiers'],
): ShortcutPreference => {
  return { key, modifiers };
};

// Global
export const GLOBAL_SHORTCUT_GROUPS = {
  COMMON: 'COMMON',
} as const;
export type GlobalShortcutGroup =
  typeof GLOBAL_SHORTCUT_GROUPS[keyof typeof GLOBAL_SHORTCUT_GROUPS];

export const GLOBAL_SHORTCUT_KEYS = {
  // COMMON
  SEARCH: 'Search',
  TOGGLE_LEFT_SIDEBAR: 'ToggleLeftSidebar',
  TOGGLE_RIGHT_SIDEBAR: 'ToggleRightSidebar',
  AI_ASSISTANT: 'AiAssistant',
} as const;
export type GlobalShortcutKey = typeof GLOBAL_SHORTCUT_KEYS[keyof typeof GLOBAL_SHORTCUT_KEYS];

export const GLOBAL_SHORTCUT_GROUP_DEFS: GroupDefs<GlobalShortcutGroup> = {
  COMMON: {
    label: 'Common',
  },
} as const;

export const GLOBAL_SHORTCUT_KEY_DEFS: KeyDefs<GlobalShortcutKey, GlobalShortcutGroup> = {
  // COMMON
  Search: {
    preference: getPreference('k', ['Mod']),
    label: 'Search',
    group: GLOBAL_SHORTCUT_GROUPS.COMMON,
  },
  ToggleLeftSidebar: {
    preference: getPreference('m', ['Mod']),
    label: 'Toggle left sidebar',
    group: GLOBAL_SHORTCUT_GROUPS.COMMON,
  },
  ToggleRightSidebar: {
    preference: getPreference('m', ['Mod', 'Alt']),
    label: 'Toggle right sidebar',
    group: GLOBAL_SHORTCUT_GROUPS.COMMON,
  },
  AiAssistant: {
    preference: getPreference('l', ['Mod']),
    label: 'AI Assistant',
    group: GLOBAL_SHORTCUT_GROUPS.COMMON,
  },
} as const;

// Notebook
const NOTEBOOK_SHORTCUT_GROUPS = {
  COMMON: 'COMMON',
  PAGE: 'PAGE',
  TEXT_FORMAT: 'TEXT_FORMAT',
  TEXT_NODE: 'TEXT_NODE',
  CODE_EDITOR: 'CODE_EDITOR',
  SQL_EDITOR: 'SQL_EDITOR',
  HEADING: 'HEADING',
  LIST: 'LIST',
} as const;

export type NotebookShortcutGroup =
  typeof NOTEBOOK_SHORTCUT_GROUPS[keyof typeof NOTEBOOK_SHORTCUT_GROUPS];

export const NOTEBOOK_SHORTCUT_GROUP_DEFS: GroupDefs<NotebookShortcutGroup> = {
  COMMON: {
    label: 'Basic',
  },
  PAGE: {
    label: 'Page control',
  },
  TEXT_FORMAT: {
    label: 'Formatting',
  },
  TEXT_NODE: {
    label: 'Text',
  },
  CODE_EDITOR: {
    label: 'Code editor',
    help: 'Commonly available in SQL editors, code blocks, etc.',
  },
  SQL_EDITOR: {
    label: 'SQL editor',
  },
  HEADING: {
    label: 'Heading',
  },
  LIST: {
    label: 'List',
  },
} as const;

export const NOTEBOOK_SHORTCUT_KEYS = {
  // COMMON
  SEARCH: 'Search',
  REPLACE: 'Replace',
  // PAGE
  MOVE_PREV_PAGE: 'MovePrevPage',
  MOVE_NEXT_PAGE: 'MoveNextPage',
  // TEXT_FORMAT
  TOGGLE_BOLD: 'ToggleBold',
  TOGGLE_ITALIC: 'ToggleItalic',
  TOGGLE_UNDERLINE: 'ToggleUnderline',
  TOGGLE_STRIKE: 'ToggleStrike',
  TOGGLE_HIGHLIGHT: 'ToggleHighlight',
  TOGGLE_CODE: 'ToggleCode',
  INLINE_EQUATION: 'InlineEquation',
  ALIGN_LEFT: 'AlignLeft',
  ALIGN_CENTER: 'AlignCenter',
  ALIGN_RIGHT: 'AlignRight',
  // TEXT_NODE
  TOGGLE_TEXT: 'ToggleText',
  TOGGLE_HEADING1: 'ToggleHeading1',
  TOGGLE_HEADING2: 'ToggleHeading2',
  TOGGLE_HEADING3: 'ToggleHeading3',
  TOGGLE_QUOTE: 'ToggleQuote',
  // CODE_EDITOR
  INDENT_LINES: 'IndentLines',
  OUTDENT_LINES: 'OutdentLines',
  MOVE_LINE_DOWN: 'MoveLineDown',
  MOVE_LINE_UP: 'MoveLineUp',
  COPY_LINE_DOWN: 'CopyLineDown',
  COPY_LINE_UP: 'CopyLineUp',
  DELETE_LINES: 'DeleteLines',
  EXPAND_LINE_SELECTION: 'ExpandLineSelection',
  SELECT_CODE_ALL: 'SelectCodeAll',
  MOVE_START_OF_CODE: 'MoveStartOfCode',
  MOVE_END_OF_CODE: 'MoveEndOfCode',
  MOVE_SQL_LINE_HEAD: 'MoveSqlLineHead',
  MOVE_SQL_LINE_TAIL: 'MoveSqlLineTail',
  // SQL_EDITOR
  TOGGLE_SQL_BLOCK_CONTENT: 'ToggleSqlBlockContent',
  EXECUTE_SQL: 'ExecuteSql',
  FORMAT_SQL: 'FormatSql',
  TOGGLE_SQL_COMMENT: 'ToggleSqlComment',
  // HEADING
  TOGGLE_HEADING_CONTENT: 'ToggleHeadingContent',
  // LIST
  TOGGLE_LIST_ITEM: 'ToggleListItem',
} as const;
export type NotebookShortcutKey =
  typeof NOTEBOOK_SHORTCUT_KEYS[keyof typeof NOTEBOOK_SHORTCUT_KEYS];

export const NOTEBOOK_SHORTCUT_KEY_DEFS: KeyDefs<NotebookShortcutKey, NotebookShortcutGroup> = {
  // COMMON
  Search: {
    preference: getPreference('f', ['Mod']),
    label: 'Search',
    group: NOTEBOOK_SHORTCUT_GROUPS.COMMON,
  },
  Replace: {
    preference: getPreference('f', ['Mod', 'Alt']),
    label: 'Replace',
    group: NOTEBOOK_SHORTCUT_GROUPS.COMMON,
  },
  // PAGE
  MovePrevPage: {
    preference: getPreference('ArrowUp', ['Mod', 'Alt']),
    label: 'Go to previous page',
    group: NOTEBOOK_SHORTCUT_GROUPS.PAGE,
  },
  MoveNextPage: {
    preference: getPreference('ArrowDown', ['Mod', 'Alt']),
    label: 'Go to next page',
    group: NOTEBOOK_SHORTCUT_GROUPS.PAGE,
  },
  // TEXT_FORMAT
  ToggleBold: {
    preference: getPreference('b', ['Mod']),
    label: 'Bold',
    group: NOTEBOOK_SHORTCUT_GROUPS.TEXT_FORMAT,
  },
  ToggleItalic: {
    preference: getPreference('i', ['Mod']),
    label: 'Italicize',
    group: NOTEBOOK_SHORTCUT_GROUPS.TEXT_FORMAT,
  },
  ToggleUnderline: {
    preference: getPreference('u', ['Mod']),
    label: 'Underline',
    group: NOTEBOOK_SHORTCUT_GROUPS.TEXT_FORMAT,
  },
  ToggleStrike: {
    preference: getPreference('x', ['Mod', 'Shift']),
    label: 'Strike-through',
    group: NOTEBOOK_SHORTCUT_GROUPS.TEXT_FORMAT,
  },
  ToggleHighlight: {
    preference: getPreference('h', ['Mod', 'Shift']),
    label: 'Highlight',
    group: NOTEBOOK_SHORTCUT_GROUPS.TEXT_FORMAT,
  },
  ToggleCode: {
    preference: getPreference('e', ['Mod']),
    label: 'Mark as code',
    group: NOTEBOOK_SHORTCUT_GROUPS.TEXT_FORMAT,
  },
  InlineEquation: {
    preference: getPreference('e', ['Mod', 'Alt']),
    label: 'Create equation',
    group: NOTEBOOK_SHORTCUT_GROUPS.TEXT_FORMAT,
  },
  AlignLeft: {
    preference: getPreference('d', ['Mod', 'Shift']),
    label: 'Align left',
    group: NOTEBOOK_SHORTCUT_GROUPS.TEXT_FORMAT,
  },
  AlignCenter: {
    preference: getPreference('e', ['Mod', 'Shift']),
    label: 'Align center',
    group: NOTEBOOK_SHORTCUT_GROUPS.TEXT_FORMAT,
  },
  AlignRight: {
    preference: getPreference('r', ['Mod', 'Shift']),
    label: 'Align right',
    group: NOTEBOOK_SHORTCUT_GROUPS.TEXT_FORMAT,
  },
  // TEXT_NODE
  ToggleText: {
    preference: getPreference('0', ['Mod', 'Shift']),
    label: 'Text',
    group: NOTEBOOK_SHORTCUT_GROUPS.TEXT_NODE,
  },
  ToggleHeading1: {
    preference: getPreference('1', ['Mod', 'Shift']),
    label: 'Heading 1',
    group: NOTEBOOK_SHORTCUT_GROUPS.TEXT_NODE,
  },
  ToggleHeading2: {
    preference: getPreference('2', ['Mod', 'Shift']),
    label: 'Heading 2',
    group: NOTEBOOK_SHORTCUT_GROUPS.TEXT_NODE,
  },
  ToggleHeading3: {
    preference: getPreference('3', ['Mod', 'Shift']),
    label: 'Heading 3',
    group: NOTEBOOK_SHORTCUT_GROUPS.TEXT_NODE,
  },
  ToggleQuote: {
    preference: getPreference('b', ['Mod', 'Shift']),
    label: 'Quote',
    group: NOTEBOOK_SHORTCUT_GROUPS.TEXT_NODE,
  },
  // CODE_EDITOR
  IndentLines: {
    preference: getPreference(']', ['Mod']),
    label: 'Indent',
    group: NOTEBOOK_SHORTCUT_GROUPS.CODE_EDITOR,
  },
  OutdentLines: {
    preference: getPreference('[', ['Mod']),
    label: 'Outdent',
    group: NOTEBOOK_SHORTCUT_GROUPS.CODE_EDITOR,
  },
  MoveLineDown: {
    preference: getPreference('ArrowDown', ['Alt']),
    label: 'Move line down',
    group: NOTEBOOK_SHORTCUT_GROUPS.CODE_EDITOR,
  },
  MoveLineUp: {
    preference: getPreference('ArrowUp', ['Alt']),
    label: 'Move line up',
    group: NOTEBOOK_SHORTCUT_GROUPS.CODE_EDITOR,
  },
  CopyLineDown: {
    preference: getPreference('ArrowDown', ['Alt', 'Shift']),
    label: 'Copy line down',
    group: NOTEBOOK_SHORTCUT_GROUPS.CODE_EDITOR,
  },
  CopyLineUp: {
    preference: getPreference('ArrowUp', ['Alt', 'Shift']),
    label: 'Copy line up',
    group: NOTEBOOK_SHORTCUT_GROUPS.CODE_EDITOR,
  },
  ExpandLineSelection: {
    preference: getPreference('l', ['Mod']),
    label: 'Select line',
    group: NOTEBOOK_SHORTCUT_GROUPS.CODE_EDITOR,
  },
  DeleteLines: {
    preference: getPreference('k', ['Mod', 'Shift']),
    label: 'Delete line',
    group: NOTEBOOK_SHORTCUT_GROUPS.CODE_EDITOR,
  },
  SelectCodeAll: {
    preference: getPreference('a', ['Mod']),
    label: 'Select all',
    group: NOTEBOOK_SHORTCUT_GROUPS.CODE_EDITOR,
  },
  MoveStartOfCode: {
    preference: getPreference('ArrowUp', ['Mod']),
    label: 'Move to the top',
    group: NOTEBOOK_SHORTCUT_GROUPS.CODE_EDITOR,
  },
  MoveEndOfCode: {
    preference: getPreference('ArrowDown', ['Mod']),
    label: 'Move to the end',
    group: NOTEBOOK_SHORTCUT_GROUPS.CODE_EDITOR,
  },
  MoveSqlLineHead: {
    preference: getPreference('ArrowLeft', ['Mod']),
    label: 'Move to the start of the line',
    group: NOTEBOOK_SHORTCUT_GROUPS.CODE_EDITOR,
  },
  MoveSqlLineTail: {
    preference: getPreference('ArrowRight', ['Mod']),
    label: 'Move to the end of the line',
    group: NOTEBOOK_SHORTCUT_GROUPS.CODE_EDITOR,
  },
  // SQL_EDITOR
  ExecuteSql: {
    preference: getPreference('Enter', ['Mod']),
    label: 'Execute SQL',
    group: NOTEBOOK_SHORTCUT_GROUPS.SQL_EDITOR,
  },
  FormatSql: {
    preference: getPreference('f', ['Mod', 'Shift']),
    label: 'Apply formatter to SQL',
    group: NOTEBOOK_SHORTCUT_GROUPS.SQL_EDITOR,
  },
  ToggleSqlComment: {
    preference: getPreference('/', ['Mod']),
    label: 'Comment-out',
    group: NOTEBOOK_SHORTCUT_GROUPS.SQL_EDITOR,
  },
  ToggleSqlBlockContent: {
    preference: getPreference('Enter', ['Mod']),
    label: 'Toggle SQL Block content',
    help: 'Inside name input',
    group: NOTEBOOK_SHORTCUT_GROUPS.SQL_EDITOR,
  },
  // HEADING
  ToggleHeadingContent: {
    preference: getPreference('Enter', ['Mod']),
    label: 'Toggle content',
    group: NOTEBOOK_SHORTCUT_GROUPS.HEADING,
  },
  // LIST
  ToggleListItem: {
    preference: getPreference('Enter', ['Mod']),
    label: 'Toggle item open/close, checked/unchecked',
    group: NOTEBOOK_SHORTCUT_GROUPS.LIST,
  },
} as const;

export const SHORTCUT_DEFS = {
  Global: {
    GROUPS: GLOBAL_SHORTCUT_GROUP_DEFS,
    KEYS: GLOBAL_SHORTCUT_KEY_DEFS,
  },
  Notebook: {
    GROUPS: NOTEBOOK_SHORTCUT_GROUP_DEFS,
    KEYS: NOTEBOOK_SHORTCUT_KEY_DEFS,
  },
} as const;

export type ShortcutDefs<C extends ShortcutContext> = typeof SHORTCUT_DEFS[C];
export type GroupsDefs<C extends ShortcutContext> = ShortcutDefs<C>['GROUPS'];
export type KeysDefs<C extends ShortcutContext> = ShortcutDefs<C>['KEYS'];
export type ShortcutGroup<C extends ShortcutContext> = keyof GroupsDefs<C>;
export type ShortcutKey<C extends ShortcutContext> = keyof KeysDefs<C>;
