import {
  connectAuthEmulator,
  getAuth,
  signOut as firebaseSignOut,
  deleteUser,
  signInWithEmailAndPassword as firebaseSignInWithEmailAndPassword,
  sendPasswordResetEmail as firebaseSendPasswordResetEmail,
  signInWithPopup,
  GoogleAuthProvider,
  EmailAuthProvider,
  unlink,
  linkWithPopup,
  linkWithCredential,
  reauthenticateWithCredential,
  reauthenticateWithPopup,
  updatePassword as updatePasswordFirebase,
  verifyBeforeUpdateEmail as firebaseVerifyBeforeUpdateEmail,
  confirmPasswordReset as firebaseConfirmPasswordReset,
  // verifyPasswordResetCode as firebaseVerifyPasswordResetCode,
  checkActionCode as firebaseCheckActionCode,
  applyActionCode as firebaseApplyActionCode,
  sendEmailVerification as firebaseSendEmailVerification,
} from 'firebase/auth';

import type { Auth, User } from 'firebase/auth';

import { initializeApp } from 'firebase/app';
import { GlobalConfig } from '@cdm/configs';

export type FirebaseUser = User;
export type Provider = 'password' | 'google.com';

const firebaseConfig = {
  apiKey: GlobalConfig.IDENTITY_PLATFORM_API_KEY,
  authDomain: GlobalConfig.IDENTITY_PLATFORM_AUTH_DOMAIN,
};

let _auth: Auth | null = null;
export const getFirebaseAuth = (): Auth => {
  if (_auth) return _auth;

  const firebase = initializeApp(firebaseConfig);
  const auth = getAuth(firebase);
  // auth.setPersistence(inMemoryPersistence);

  if (GlobalConfig.ENABLE_FIREBASE_AUTH_EMULATOR) {
    connectAuthEmulator(auth, GlobalConfig.FIREBASE_AUTH_EMULATOR_HOST);
  }
  _auth = auth;

  return auth;
};

export async function getFirebaseUser(): Promise<User> {
  return new Promise((resolve, reject) => {
    const unsubscribe = getFirebaseAuth().onAuthStateChanged(user => {
      unsubscribe();
      if (!user) return reject(new Error('no sessions'));
      else return resolve(user);
    });
  });
}

export function getCurrentUser() {
  return getFirebaseAuth().currentUser;
}

export async function reloadAndGetFirebaseUser(): Promise<User> {
  const user = getCurrentUser();
  if (!user) {
    throw new Error('User is not logged in');
  }
  await user.reload();
  return user;
}

export function signInWithEmailAndPassword(email: string, password: string) {
  return firebaseSignInWithEmailAndPassword(getFirebaseAuth(), email, password);
}

export function signInWithGoogle(email?: string) {
  const provider = new GoogleAuthProvider();
  if (email) {
    provider.setCustomParameters({
      login_hint: email,
    });
  }
  return signInWithPopup(getFirebaseAuth(), provider);
}

export function linkWithPassword(user: User, password: string) {
  if (!user.email) {
    throw new Error('Email is not set');
  }

  const credential = EmailAuthProvider.credential(user.email, password);
  return linkWithCredential(user, credential);
}

export async function reauthenticateWithPassword(user: User, password: string) {
  if (!user.email) {
    throw new Error('Email is not set');
  }

  const credential = EmailAuthProvider.credential(user.email, password);
  return reauthenticateWithCredential(user, credential);
}

export async function reauthenticateWithGoogle(user: User) {
  const provider = new GoogleAuthProvider();
  if (user.email) {
    provider.setCustomParameters({
      login_hint: user.email,
    });
  }

  return reauthenticateWithPopup(user, provider);
}

export async function updatePassword(user: User, newPassword: string) {
  await updatePasswordFirebase(user, newPassword);
}

export function linkWithGoogle(user: User) {
  const provider = new GoogleAuthProvider();
  if (user.email) {
    provider.setCustomParameters({
      login_hint: user.email,
    });
  }

  return linkWithPopup(user, provider);
}

export function sendPasswordResetEmail(email: string) {
  return firebaseSendPasswordResetEmail(getFirebaseAuth(), email);
}

export const signOut = () => {
  return firebaseSignOut(getFirebaseAuth());
};

export function unlinkProvider(providerId: Provider) {
  const user = getCurrentUser();
  if (!user) {
    throw new Error('User is not logged in');
  }

  return unlink(user, providerId);
}

export const firebaseDeleteUser = async () => {
  const auth = getFirebaseAuth();
  if (!auth.currentUser) {
    throw new Error('User is not logged in');
  }
  try {
    await deleteUser(auth.currentUser);
  } catch (err: any) {
    const msg = err.message as string;
    // https://firebase.google.com/docs/reference/js/auth?hl=ja#autherrorcodes
    // https://firebase.google.com/docs/auth/web/manage-users?hl=ja#re-authenticate_a_user
    if (msg.includes('requires-recent-login')) {
      // FIXME: 本当はここで再ログインを促すダイアログを出したいがUI面倒なのでエラーメッセージで再ログインを促す
      throw new Error("You haven't logged in recently. Please signout and login again.");
    }
    throw err;
  }
};

export function verifyBeforeUpdateEmail(user: User, email: string) {
  return firebaseVerifyBeforeUpdateEmail(user, email);
}

// export function verifyPasswordResetCode(code: string): Promise<string> {
//   return firebaseVerifyPasswordResetCode(getFirebaseAuth(), code);
// }

export async function confirmResetPassword(code: string, newPassword: string) {
  return firebaseConfirmPasswordReset(getFirebaseAuth(), code, newPassword);
}

export async function checkActionCode(code: string) {
  return firebaseCheckActionCode(getFirebaseAuth(), code);
}

export async function applyActionCode(code: string) {
  return firebaseApplyActionCode(getFirebaseAuth(), code);
}

// 通常ケースでは使わないが一応用意しておく
export async function sendEmailVerification() {
  return firebaseSendEmailVerification(getCurrentUser() as User);
}
