import { Plugin, PluginKey } from '@tiptap/pm/state';
import type { Node as ProsemirrorNode } from '@tiptap/pm/model';
import { Decoration, DecorationSet } from '@tiptap/pm/view';
import {
  tokenizeSqlBlockForSyntaxHighlight,
  SYNTAX_HIGHLIGHT_TOKEN_TYPES,
  HIGHLIGHT_TOKENS,
  INVISIBLE_CHARACTERS_CLASS_MAP,
  type SyntaxHighlightToken,
} from '@cdm/libs/sql-sytanx-highlight-tokenizer';

const createDecorations = ({
  doc,
  nodeName,
}: {
  doc: ProsemirrorNode;
  nodeName: string;
}): { tokens: SyntaxHighlightToken[]; decorationSet: DecorationSet } => {
  const tokens: SyntaxHighlightToken[] = [];
  const decorations: Decoration[] = [];

  doc.descendants((node, pos, parent) => {
    if (node.type.name !== nodeName) return;
    let from = pos + 1;
    const contents = node.toJSON()?.content || [];
    const tmpTokens = tokenizeSqlBlockForSyntaxHighlight(contents);

    tmpTokens.forEach((token, i) => {
      tokens.push(token);
      const to = from + token.size;
      if (HIGHLIGHT_TOKENS.includes(token.type)) {
        // token単位でハイライト
        decorations.push(
          Decoration.inline(
            from,
            to,
            {
              class: `token-${token.type}`,
            },
            {},
          ),
        );
      } else if (token.type === SYNTAX_HIGHLIGHT_TOKEN_TYPES.WHITESPACE && token.text) {
        for (const [i, c] of token.text.split('').entries()) {
          if (INVISIBLE_CHARACTERS_CLASS_MAP[c]) {
            decorations.push(
              Decoration.inline(
                from + i,
                from + i + 1,
                { class: `ws-Character ${INVISIBLE_CHARACTERS_CLASS_MAP[c]}` },
                {},
              ),
            );
          }
        }
      }
      from = to;
    });
  });

  return { tokens, decorationSet: DecorationSet.create(doc, decorations) };
};

type PluginState = {
  tokens: SyntaxHighlightToken[];
  decorationSet: DecorationSet;
};

export const createSyntaxHighlightPlugin = (nodeName: string): Plugin => {
  return new Plugin<PluginState>({
    key: new PluginKey('syntaxHighlight'),
    state: {
      init: (_, { doc }) => {
        return createDecorations({ doc, nodeName });
      },
      apply: (transaction, oldPluginState, oldState, newState) => {
        let newPluginState = { ...oldPluginState };
        if (transaction.docChanged) {
          newPluginState = createDecorations({ doc: transaction.doc, nodeName });
        } else {
          newPluginState = {
            tokens: newPluginState.tokens,
            decorationSet: newPluginState.decorationSet.map(transaction.mapping, transaction.doc),
          };
        }
        return newPluginState;
      },
    },
    props: {
      decorations(state) {
        return this.getState(state)!.decorationSet;
      },
    },
  });
};
