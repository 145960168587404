import { AnalyticsBrowser } from '@segment/analytics-next';
import type { App } from 'vue';
import type { IdentifyProps, IdentifyPropsWithCompany, TrackerModule } from '../types';

const env = window.__VITE_META_ENV_MODE__;

export class SegmentTracker implements TrackerModule {
  segment: AnalyticsBrowser;
  constructor(segmentWriteKey: string) {
    this.segment = AnalyticsBrowser.load({ writeKey: segmentWriteKey });
  }
  init(app: App<any>): void {
    // do nothing
  }
  identifyWithCompany({ id, name, email, company }: IdentifyPropsWithCompany): void {
    this.segment.group(company.id, { name: company.name });
    // this.segment.identify(id, { name, email, company, env }, {});
  }
  view(): void {
    this.segment.page({}, {}, { env });
  }
  track(event: string, properties: any) {
    this.segment.track(event, { ...properties, env });
  }
  openChat(): void {
    // do nothing
  }
  identify({ id, name, email, intercomHash }: IdentifyProps): void {
    let additionalProps = {};
    if (intercomHash !== '') {
      additionalProps = { Intercom: { user_hash: intercomHash } };
    }
    this.segment.identify(id, { name, email, env }, additionalProps);
  }
}
