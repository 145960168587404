import type { SqlPreferences } from '@cdm/@generated-account-client/shared-types';
import { cloneDeep } from 'lodash-es';
import AccountStore from '@cdm/domains/account/shared/stores/AccountStore';

class Store {
  state: {
    preferences: SqlPreferences;
  };

  constructor() {
    this.state = {
      preferences: cloneDeep(AccountStore.getPreference('sql')),
    };
  }

  public refresh(): void {
    // オブジェクト内のfieldを上書きするとstore側の値も変わってしまうのでcloneしてから返す
    this.state.preferences = cloneDeep(AccountStore.getPreference('sql'));
  }

  public getEnableTabIndent(): boolean {
    return this.state.preferences.enableTabIndent;
  }

  public getIndentSize(): number {
    return this.state.preferences.indentSize;
  }

  public getIndentChars(): string {
    const useTab = this.getEnableTabIndent();
    return useTab ? '\t' : ' '.repeat(this.getIndentSize());
  }

  public getDisableAutocomplete(): boolean {
    return !this.state.preferences.enableAutoComplete;
  }

  public getDisableFunctionAutocomplete(): boolean {
    return !this.state.preferences.enableFunctionAutocomplete;
  }

  public getDisableReservedWordAutocomplete(): boolean {
    return !this.state.preferences.enableReservedWordAutocomplete;
  }

  public getDisableCodeSnippetAutocomplete(): boolean {
    return !this.state.preferences.enableCodeSnippetAutocomplete;
  }

  public getDisableRefItemFieldAutocomplete(): boolean {
    return !this.state.preferences.enableRefItemFieldAutocomplete;
  }

  public getPreferLowerKeyword(): boolean {
    return this.state.preferences.preferLowerKeyword;
  }

  public getAddAliasByDefault(): boolean {
    return !!this.state.preferences.addAliasByDefault;
  }

  public getDisableFunctionTooltip(): boolean {
    return !this.state.preferences.enableFunctionTooltip;
  }
}

let store: Store | null = null;
// MEMO: AccountStore の初期化前に呼ばないようにする必要がある
export const getSqlEditorSettingsStore = (): Store => {
  if (!store) {
    store = new Store();
  }
  return store;
};
