import type { App } from 'vue';
import {
  install as _install,
  uninstall as _uninstall,
  eventToHotkeyString,
  normalizeHotkey,
  type NormalizedHotkeyString,
} from '@github/hotkey';

const ATTR_KEY = 'data-hotkey';
let isSuspended = false;
let listeningHotkeys: { el: HTMLElement; hotkey: NormalizedHotkeyString }[] = [];

const install = (el: HTMLElement) => {
  _install(el);
  const hotkey = el.getAttribute(ATTR_KEY) as string;
  if (hotkey) {
    listeningHotkeys = listeningHotkeys.filter(item => item.el !== el);
    listeningHotkeys.push({ el, hotkey: normalizeHotkey(hotkey) });
  }
};
const uninstall = (el: HTMLElement) => {
  _uninstall(el);
  listeningHotkeys = listeningHotkeys.filter(item => item.el !== el);
};

// v-hotkey="key" で github/hotkey を使えるようにする directive
export default {
  install(app: App) {
    app.directive('hotkey', {
      beforeMount(el: HTMLElement, binding, vnode) {
        if (binding.value) {
          el.setAttribute(ATTR_KEY, binding.value);
          if (!isSuspended) {
            install(el);
          }
        } else {
          uninstall(el);
          el.removeAttribute(ATTR_KEY);
        }
      },
      beforeUpdate(el: HTMLElement, binding, vnode) {
        if (binding.value) {
          el.setAttribute(ATTR_KEY, binding.value);
          if (!isSuspended) {
            install(el);
          }
        } else {
          uninstall(el);
          el.removeAttribute(ATTR_KEY);
        }
      },
      beforeUnmount(el: HTMLElement, binding, vnode) {
        uninstall(el);
        el.removeAttribute(ATTR_KEY);
      },
    });
  },
  // 一時的に hotkey を無効化する
  suspend() {
    document.querySelectorAll(`[${ATTR_KEY}]`).forEach(el => {
      if (el instanceof HTMLElement) {
        // 一時的な無効化の時は attr を変更せずに、uninstall だけする（再開時に使う）
        uninstall(el);
      }
    });
    isSuspended = true;
  },
  // suspend で無効化した hotkey を再開する
  resume() {
    document.querySelectorAll(`[${ATTR_KEY}]`).forEach(el => {
      if (el instanceof HTMLElement) {
        const value = el.getAttribute(ATTR_KEY);
        if (value) {
          install(el);
        }
      }
    });
    isSuspended = false;
  },
  /**
   * notebook-editor に focus がある時にも反応できるように
   * hotkey に登録されている key への event かを判定する
   * (sequence には対応していないので注意)
   */
  isListened(ev: KeyboardEvent): boolean {
    if (isSuspended) return false;
    return listeningHotkeys.some(item => item.hotkey === eventToHotkeyString(ev));
  },
  getDisplayCode(hotkey: string): string {
    // MEMO: 使っている範囲で適当に定義しているだけなので、必要に応じて修正
    hotkey = normalizeHotkey(hotkey) as string;
    hotkey = hotkey.replace(/Meta/g, '⌘').replace(/Control/g, 'Ctrl');
    return hotkey;
  },
};

/**
 * a-input みたいに input 要素に直接 hotkey を設定できない時に
 * @hotkey-fire 経由で子の input 要素に focus するために使う
 * 例:
 * <a-input
 *   v-hotkey="xxx"
 *   @hotkey-fire="focusToChildInput"
 *   ...
 * />
 */
export const focusToChildInput = (ev: KeyboardEvent) => {
  const el = ev.target as HTMLElement;
  if (!(el instanceof HTMLElement)) return;
  const input = el.querySelector('input, textarea');
  if (input instanceof HTMLElement) {
    input.focus();
  }
};
