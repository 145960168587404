import { TextSelection } from '@tiptap/pm/state';
import type { ResolvedPos } from '@tiptap/pm/model';
import { GapCursor } from '@tiptap/pm/gapcursor';
import { NODE_NAMES } from '@cdm/@shared-server-notebook/constants/notebook';
import { mergeKeyboardShortcutBindings, chainKeyboardShortcutsCommand } from './utils';
import type { KeyboardShortcutCommand, KeyboardShortcutBindings } from './utils';
import { registerShortcuts, SHORTCUT_KEYS } from './ShortcutKeys';
import { getSqlEditorSettingsStore } from '@cdm/domains/account/shared/stores/SqlEditorSettingsStore';

const moveToAnchorStart: KeyboardShortcutCommand = ({ editor }) => {
  return editor.commands.focus(editor.state.selection.$anchor.start());
};
const moveToAnchorEnd: KeyboardShortcutCommand = ({ editor }) => {
  return editor.commands.focus(editor.state.selection.$anchor.end());
};

const _findLineStartBefore = (pos: number, $anchor: ResolvedPos): number => {
  const anchorStart = $anchor.start();
  const currentPos = pos;
  let lineEnd: number | null = null;
  $anchor.parent.forEach((child, offset) => {
    if (anchorStart + offset > currentPos) {
      return;
    }
    // text 以外のノードも含まれている前提で探す
    if (child.type.name !== NODE_NAMES.TEXT || !child.text) return;
    const targetIndex = child.text.slice(0, currentPos - anchorStart - offset).lastIndexOf('\n');
    if (targetIndex >= 0) {
      lineEnd = anchorStart + offset + targetIndex;
    }
  });
  return lineEnd !== null ? lineEnd + 1 : anchorStart;
};

const _findLineStartsBetween = (from: number, to: number, $anchor: ResolvedPos): number[] => {
  const found: number[] = [];
  let pos = from;
  while (pos < to) {
    pos = _findLineEndAfter(pos, $anchor) + 1;
    if (pos < to) {
      found.push(pos);
    }
  }
  return found;
};

const _findLineEndAfter = (pos: number, $anchor: ResolvedPos): number => {
  const anchorStart = $anchor.start();
  const currentPos = pos;
  let lineEnd: number | null = null;
  $anchor.parent.forEach((child, offset) => {
    if (lineEnd !== null) return;
    if (anchorStart + offset + child.nodeSize < currentPos) {
      return;
    }
    // text 以外のノードも含まれている前提で探す
    if (child.type.name !== NODE_NAMES.TEXT || !child.text) return;
    const targetIndex = child.text.indexOf('\n', currentPos - anchorStart - offset);
    if (targetIndex >= 0) {
      lineEnd = anchorStart + offset + targetIndex;
    }
  });
  return lineEnd !== null ? lineEnd : $anchor.end();
};

const moveToLineStart: KeyboardShortcutCommand = ({ editor }) => {
  const { $anchor, from } = editor.state.selection;
  const lineStart = _findLineStartBefore(from, $anchor);
  return editor.commands.focus(lineStart);
};

const moveToLineEnd: KeyboardShortcutCommand = ({ editor }) => {
  const { $anchor, to } = editor.state.selection;
  const lineEnd = _findLineEndAfter(to, $anchor);
  return editor.commands.focus(lineEnd);
};

const _getTextFromLineStart = (
  lineStart: number,
  $anchor: ResolvedPos,
  maxSize: number,
): string => {
  if (maxSize <= 0) return '';
  const anchorStart = $anchor.start();
  const textFrom = lineStart - anchorStart;
  const textTo = Math.min(lineStart - anchorStart + maxSize, $anchor.end() - anchorStart);
  if (textFrom === textTo) return '';
  return $anchor.parent.textBetween(textFrom, textTo, '|', '*');
};

const _getLineTextByLineStart = (lineStart: number, $anchor: ResolvedPos): string => {
  const lineEnd = _findLineEndAfter(lineStart, $anchor);
  const lineText = _getTextFromLineStart(lineStart, $anchor, lineEnd - lineStart);
  return lineText;
};

const _getIndentCharsByLineStart = (lineStart: number, $anchor: ResolvedPos): string => {
  return _getLineTextByLineStart(lineStart, $anchor).match(/^([ \t]+)/)?.[0] || '';
};

// 基本的には VSCode の挙動に合わせていく
const insertIndentByTab: KeyboardShortcutCommand = ({ editor }) => {
  const { empty, from, to, $anchor } = editor.state.selection;
  const indentChars = getSqlEditorSettingsStore().getIndentChars();
  if (empty) {
    if (indentChars.length === 1) {
      return editor.commands.insertContent(indentChars);
    } else {
      const lineStart = _findLineStartBefore(from, $anchor);
      const charsFromLineStart = from - lineStart;
      let insertSize = indentChars.length - (charsFromLineStart % indentChars.length);
      if (insertSize === 0) insertSize = indentChars.length;
      return editor.commands.insertContent(indentChars[0].repeat(insertSize));
    }
  } else {
    const lineStarts = Array.from(
      new Set([_findLineStartBefore(from, $anchor), ..._findLineStartsBetween(from, to, $anchor)]),
    );
    // 1行がまるっと選択されている場合は indent する
    const firstLineEnd = _findLineEndAfter(lineStarts[0], $anchor);
    if (lineStarts.length === 1 && !(from === lineStarts[0] && to === firstLineEnd)) {
      return editor.commands.insertContent(indentChars);
    } else {
      return indentLines({ editor });
    }
  }
};

const indentLines: KeyboardShortcutCommand = ({ editor }) => {
  const { from, to, $anchor, empty } = editor.state.selection;
  const indentChars = getSqlEditorSettingsStore().getIndentChars();
  const lineStarts = Array.from(
    new Set([_findLineStartBefore(from, $anchor), ..._findLineStartsBetween(from, to, $anchor)]),
  );
  const chain = editor.chain();
  let offset = 0;
  for (const lineStart of lineStarts) {
    const textFromLineStart = _getTextFromLineStart(lineStart, $anchor, indentChars.length);
    const whiteSpaceSize = textFromLineStart.match(/^([ \t]+)/)?.[0].length || 0;
    // vscode に合わせて indentChars の倍数になるようにする
    let insertSize = indentChars.length - (whiteSpaceSize % indentChars.length);
    if (insertSize === 0) insertSize = indentChars.length;
    chain.insertContentAt(lineStart + offset, indentChars[0].repeat(insertSize));
    offset += insertSize;
  }
  chain.setTextSelection({
    from: empty ? from + offset : from,
    to: to + offset,
  });
  return chain.run();
};

const removeIndentByTab: KeyboardShortcutCommand = ({ editor }) => {
  const { empty, from, $anchor } = editor.state.selection;
  const indentChars = getSqlEditorSettingsStore().getIndentChars();
  if (empty) {
    const lineStart = _findLineStartBefore(from, $anchor);
    const textFromLineStart = _getTextFromLineStart(lineStart, $anchor, indentChars.length);
    const deleteSize = textFromLineStart.match(/^([ \t]+)/)?.[0].length;
    if (!deleteSize) {
      // 消すものがないので何もしない
      return true;
    }
    return editor.commands.deleteRange({
      from: lineStart,
      to: lineStart + deleteSize,
    });
  } else {
    return outdentLines({ editor });
  }
};

const outdentLines: KeyboardShortcutCommand = ({ editor }) => {
  const { from, to, $anchor, empty } = editor.state.selection;
  const indentChars = getSqlEditorSettingsStore().getIndentChars();
  const lineStarts = Array.from(
    new Set([_findLineStartBefore(from, $anchor), ..._findLineStartsBetween(from, to, $anchor)]),
  );
  const chain = editor.chain();
  let offset = 0;
  for (const lineStart of lineStarts) {
    const textFromLineStart = _getTextFromLineStart(lineStart, $anchor, indentChars.length);
    const deleteSize = textFromLineStart.match(/^([ \t]+)/)?.[0].length;
    if (!deleteSize) {
      continue;
    }
    chain.deleteRange({
      from: lineStart + offset,
      to: lineStart + offset + deleteSize,
    });
    offset -= deleteSize;
  }
  chain.setTextSelection({
    from: empty ? from + offset : from,
    to: to + offset,
  });
  return chain.run();
};

// indent で挿入した複数のスペースを backspace で一気に削除できるように
const deleteIndentMultipleSpaces: KeyboardShortcutCommand = ({ editor }) => {
  const { empty, $anchor, from } = editor.state.selection;
  if (!empty) return false;
  const indentChars = getSqlEditorSettingsStore().getIndentChars();
  if (indentChars.length === 1) return false;
  const anchorStart = $anchor.start();
  if (from - anchorStart >= indentChars.length) {
    const beforeText = $anchor.parent.textBetween(
      from - anchorStart - indentChars.length,
      from - anchorStart,
      '|',
      '*',
    );
    // indent っぽい位置以外(= 行頭までに空白文字列以外が存在する)では発動しないようにする
    const lineTextBefore = $anchor.parent.textBetween(
      _findLineStartBefore(from, $anchor) - anchorStart,
      from - anchorStart,
      '|',
      '*',
    );
    if (beforeText === indentChars && lineTextBefore.trim() === '') {
      editor.commands.deleteRange({ from: from - indentChars.length, to: from });
      return true;
    }
  }
  return false;
};

const baskacpeOnNodeSpacer: KeyboardShortcutCommand = ({ editor }) => {
  const sel = editor.state.selection;
  if (sel.empty && sel.from === sel.to) {
    const { $from } = sel;
    if (
      !$from.nodeAfter?.type.isText &&
      $from.nodeAfter?.type.isAtom &&
      $from.nodeBefore?.type.name === NODE_NAMES.TEXT &&
      $from.nodeBefore.text?.endsWith('\n')
    ) {
      // 改行直後の sqlBlockRef 等のノードの手間に無理やりカーソルを移動できるようにしている関係で、
      // その場所にカーソルがある時に backspace が押されるとバグっぽい挙動になるので、無理やり修正する処理
      // ('NodeSpacer' で検索すると該当コードが見つかるはず)
      editor.state.tr.setSelection(TextSelection.create(editor.state.doc, $from.pos - 1));
    }
  }
  return false;
};

const exitCodeBlockIfAtEnd: KeyboardShortcutCommand = ({ editor }) => {
  const { $anchor, to } = editor.state.selection;
  const lineEnd = _findLineEndAfter(to, $anchor);
  if (lineEnd === $anchor.end()) {
    const $pos = editor.state.doc.resolve($anchor.end() + 1);
    const gapCursor = new GapCursor($pos);
    editor
      .chain()
      .command(({ tr }) => {
        tr.setSelection(gapCursor);
        return true;
      })
      .focus($pos.pos, { scrollIntoView: true })
      .run();
    return true;
  }
  return false;
};

const preventArrowDownMoveOutOfCodeBlock: KeyboardShortcutCommand = ({ editor }) => {
  /**
   * MEMO:
   * endOfTextblock の判定が怪しくて(複数行対応を想定していない？)コードの下半分にカーソルがあって、
   * 次の兄弟Nodeが存在している場合に、ArrowDownが入力されるといきなり兄弟Nodeに飛ばされる不具合対策
   */
  const { $from, $anchor, from } = editor.state.selection;
  const isAtEnd = $from.parentOffset === $from.parent.nodeSize - 2;
  if (!isAtEnd) {
    const lineStart = _findLineStartBefore(from, $anchor);
    const lineEnd = _findLineEndAfter(from, $anchor);
    const nextLineStart = lineEnd + 1;
    const nextLineEnd = _findLineEndAfter(lineEnd + 1, $anchor);
    const charsFromLineStart = from - lineStart;
    // 次の行の先頭から同じ文字数のところか、次の行の末尾に移動
    // FIXME: マルチバイト文字があるとカーソルが真下に移動しない…、上記MEMOにある根本原因の方を直すべき
    const moveTo = Math.min(nextLineStart + charsFromLineStart, nextLineEnd, $anchor.end());
    editor.commands.focus(moveTo);
    return true;
  }
  return false;
};

const enterWithIndent: KeyboardShortcutCommand = ({ editor }) => {
  const { $anchor, from } = editor.state.selection;
  const anchorStart = $anchor.start();
  const lineStart = _findLineStartBefore(from, $anchor);
  const textFromLineStart = $anchor.parent.textBetween(
    lineStart - anchorStart,
    from - anchorStart,
    '|',
    '*',
  );
  const indentText = textFromLineStart.match(/^([ \t]+)/)?.[0];
  if (indentText) {
    editor.commands.insertContent({ type: NODE_NAMES.TEXT, text: '\n' + indentText });
    setTimeout(() => {
      // かなり微妙だが横スクロールが発生した状態で、改行した後に行頭までスクロールされない問題に対応するため
      // FIXME: CSSとかでもう少し改善できないだろうか…
      editor.commands.command(({ tr }) => {
        tr.scrollIntoView();
        return true;
      });
    }, 0);
    return true;
  }
  return false;
};

// ()とか[]とか{}とかの入力補完
const _createGroupBracketInputShortcut = (
  startChar: string,
  endChar: string,
): KeyboardShortcutCommand => {
  return ({ editor }) => {
    const { $from, $to } = editor.state.selection;
    // 直後に文字がある場合は閉じ括弧の補完はしない
    const afterChar = $to.nodeAfter?.text?.[0];
    if (afterChar?.trim()) return false;
    // 何かが選択されている場合は、選択範囲の前後に挿入される仕様
    return editor
      .chain()
      .insertContentAt($from.pos, startChar)
      .insertContentAt($to.pos + startChar.length, endChar)
      .command(({ tr, state }) => {
        // 閉じ括弧の手前にカーソル移動
        tr.setSelection(TextSelection.create(state.doc, state.selection.$to.pos - endChar.length));
        return true;
      })
      .run();
  };
};

// _createGroupBracketInputShortcut で ) を自動入力した後に ) が入力された場合に無視する処理
const _createSkipCloseBracketInputShortcut = (endChar: string): KeyboardShortcutCommand => {
  return ({ editor }) => {
    const { $to } = editor.state.selection;
    if ($to.nodeAfter?.text?.startsWith(endChar)) {
      return editor.commands.command(({ tr, state }) => {
        // 何も入力せずに ) の後に移動
        tr.setSelection(TextSelection.create(state.doc, state.selection.$to.pos + 1));
        return true;
      });
    }
    return false;
  };
};

const BRACKET_PAIRS: [string, string][] = [
  ['(', ')'],
  ['{', '}'],
  ['[', ']'],
];

const createBracketShortcutBindings = (
  bracketPairs: [string, string][],
): KeyboardShortcutBindings => {
  const bindings: KeyboardShortcutBindings = {};
  bracketPairs.forEach(([startChar, endChar]) => {
    bindings[startChar] = _createGroupBracketInputShortcut(startChar, endChar);
    bindings[endChar] = _createSkipCloseBracketInputShortcut(endChar);
  });
  return bindings;
};

// 括弧の入力補完の逆で、削除もまとめてできるようにする
const createCommandSelectBracketPairToDelete =
  (bracketPairs: [string, string][]): KeyboardShortcutCommand =>
  ({ editor }) => {
    const { empty, $from, $to } = editor.state.selection;
    if (!empty) return false;
    bracketPairs.forEach(([startChar, endChar]) => {
      if (
        $from.nodeBefore?.text?.endsWith(startChar) &&
        $from.nodeAfter?.text?.startsWith(endChar)
      ) {
        editor.commands.setTextSelection({
          from: $from.pos - startChar.length,
          to: $to.pos + endChar.length,
        });
      }
    });
    return false;
  };

//  " の入力をいい感じにする
const _createCommandEncloseByQuote = (quoteChar: string): KeyboardShortcutCommand => {
  return ({ editor }) => {
    const { empty, $from, from, to } = editor.state.selection;
    if (!empty) {
      // 範囲選択中に " を入力したら選択中の範囲を"で囲う
      return editor
        .chain()
        .insertContentAt(from, quoteChar)
        .insertContentAt(to + quoteChar.length, quoteChar)
        .command(({ tr, state }) => {
          // 入力前に元々選択していた範囲を選択し直す
          tr.setSelection(
            TextSelection.create(state.doc, from + quoteChar.length, to + quoteChar.length),
          );
          return true;
        })
        .run();
    }

    const prevChar = $from.nodeBefore?.text?.slice(-1);
    const nextChar = $from.nodeAfter?.text?.slice(0, 1);

    // 前後に " がある場合は、普通に " を入力
    if (prevChar === quoteChar || nextChar === quoteChar) {
      return false;
    }

    /**
     * 前後に英数字がない場合は " を入力したら "|" にする(|がカーソル位置)
     *
     * 前か後ろに英数字がある場合は "" ではなく " だけにするのは、例えば test を quote で囲む時に
     * 以下のような順序で入力した時に違和感がないようにするためだと思われる
     * （という意味では、英数字だけでなく、ひらがなや漢字等がある時も同等の扱いにすべきだが…面倒なのでやっていない）
     *
     * 1. |test
     * 2. "|test
     * 3. "test|
     * 4. "test"|
     */
    if (!prevChar?.match(/[\w]/) && !nextChar?.match(/^[\w]/)) {
      return editor
        .chain()
        .insertContentAt(from, quoteChar + quoteChar)
        .focus(from + 1)
        .run();
    }
    return false;
  };
};

// " の入力補完の逆で、削除もまとめてできるようにする
const createCommandSelectQuotePairToDelete =
  (quoteChars: string[]): KeyboardShortcutCommand =>
  ({ editor }) => {
    const { empty, $from, $to } = editor.state.selection;
    if (!empty) return false;
    quoteChars.forEach(quoteChar => {
      if (
        $from.nodeBefore?.text?.endsWith(quoteChar) &&
        $from.nodeAfter?.text?.startsWith(quoteChar)
      ) {
        editor.commands.setTextSelection({
          from: $from.pos - quoteChar.length,
          to: $to.pos + quoteChar.length,
        });
      }
    });
    return false;
  };

const QUOTES = ["'", '"', '`'];

const createQuoteShortcutBindings = (quoteChars: string[]): KeyboardShortcutBindings => {
  const bindings: KeyboardShortcutBindings = {};
  quoteChars.forEach(char => {
    bindings[char] = _createCommandEncloseByQuote(char);
  });
  return bindings;
};

const SQL_ONELINE_COMMENT_REGEXP = /^[\s]*((--|#)[\s]{0,1})/;
const SQL_ONELINE_COMMENT_TO_INSERT = '-- ';

const _isCommentLine = (lineStart: number, $anchor: ResolvedPos): boolean => {
  const lineEnd = _findLineEndAfter(lineStart, $anchor);
  const txt = _getTextFromLineStart(lineStart, $anchor, lineEnd - lineStart);
  return !!txt.match(SQL_ONELINE_COMMENT_REGEXP);
};

const _getCommentRangeOfLineToDelete = (
  lineStart: number,
  $anchor: ResolvedPos,
): { from: number; to: number } | null => {
  const lineEnd = _findLineEndAfter(lineStart, $anchor);
  const txt = _getTextFromLineStart(lineStart, $anchor, lineEnd - lineStart);
  const matched = txt.match(SQL_ONELINE_COMMENT_REGEXP);
  if (!matched) return null;
  const offset = txt.indexOf(matched[1]);
  return { from: lineStart + offset, to: lineStart + offset + matched[1].length };
};

const toggleSqlComment: KeyboardShortcutCommand = ({ editor }) => {
  const { from, to, $anchor } = editor.state.selection;
  let offset = 0;
  const lineStarts = Array.from(
    new Set([_findLineStartBefore(from, $anchor), ..._findLineStartsBetween(from, to, $anchor)]),
  );
  let chain = editor.chain();
  if (lineStarts.every(lineStart => _isCommentLine(lineStart, $anchor))) {
    // すべてコメント行だったらコメント削除
    for (const lineStart of lineStarts) {
      const range = _getCommentRangeOfLineToDelete(lineStart, $anchor);
      if (range) {
        chain = chain.deleteRange({
          from: range.from + offset,
          to: range.to + offset,
        });
        offset -= range.to - range.from;
      }
    }
  } else {
    // それ以外はコメント追加
    for (const lineStart of lineStarts) {
      chain = chain.insertContentAt(lineStart + offset, SQL_ONELINE_COMMENT_TO_INSERT, {
        updateSelection: false,
      });
      offset += SQL_ONELINE_COMMENT_TO_INSERT.length;
    }
    if (from !== to) {
      chain.setTextSelection({ from, to: to + offset });
    }
  }
  return chain.run();
};

const cutLineIfSelectionEmpty: KeyboardShortcutCommand = ({ editor }) => {
  const { empty, from, $anchor } = editor.state.selection;
  if (!empty) return false;
  const lineStart = _findLineStartBefore(from, $anchor);
  const lineEnd = _findLineEndAfter(from, $anchor);
  const range = {
    from: lineStart,
    to: Math.min(lineEnd + 1, $anchor.end()),
  };
  return editor
    .chain()
    .setTextSelection(range)
    .command(({ state }) => {
      // FIXME: text 以外が含まれている場合に意図した挙動にならない
      const textSelection = state.doc.textBetween(state.selection.from, state.selection.to);
      window.navigator.clipboard.writeText(textSelection || '');
      return true;
    })
    .deleteRange(range)
    .run();
};

const copyLineIfSelectionEmpty: KeyboardShortcutCommand = ({ editor }) => {
  const { empty, from, $anchor } = editor.state.selection;
  if (!empty) return false;
  const lineStart = _findLineStartBefore(from, $anchor);
  const lineEnd = _findLineEndAfter(from, $anchor);
  // FIXME: text 以外が含まれている場合に意図した挙動にならない
  const lineText = editor.state.doc.textBetween(lineStart, Math.min(lineEnd + 1, $anchor.end()));
  window.navigator.clipboard.writeText(lineText || '');
  return true;
};

const deleteLines: KeyboardShortcutCommand = ({ editor }) => {
  const { from, to, $anchor } = editor.state.selection;
  const lineStart = _findLineStartBefore(from, $anchor);
  const lineEnd = _findLineEndAfter(to, $anchor);
  return editor.commands.deleteRange({
    from: lineStart,
    to: Math.min(lineEnd + 1, $anchor.end()),
  });
};

// FIXME: SQL実行と衝突
// const insertLineBelow: KeyboardShortcutCommand = ({ editor }) => {
//   const { to, $anchor } = editor.state.selection;
//   const lineStart = _findLineStartBefore(to, $anchor);
//   const lineEnd = _findLineEndAfter(to, $anchor);
//   const indentChars = _getIndentCharsByLineStart(lineStart, $anchor);
//   const insertChars = `\n${indentChars}`;
//   return editor
//     .chain()
//     .insertContentAt(lineEnd, insertChars)
//     .focus(lineEnd + insertChars.length)
//     .run();
// };
// const insertLineAbove: KeyboardShortcutCommand = ({ editor }) => {
//   const { to, $anchor } = editor.state.selection;
//   const anchorStart = $anchor.start();
//   const lineStart = _findLineStartBefore(to, $anchor);
//   const prevLineStart =
//     lineStart - 1 >= anchorStart ? _findLineStartBefore(lineStart - 1, $anchor) : null;
//   const indentChars = _getIndentCharsByLineStart(prevLineStart || lineStart, $anchor);
//   const insertChars = `${indentChars}\n`;
//   return editor
//     .chain()
//     .insertContentAt(lineStart, insertChars)
//     .command(() => {
//       // 何故か次の行に勝手に移動してしまうケースがあるので非同期で focus
//       setTimeout(() => {
//         editor.commands.focus(lineStart + indentChars.length);
//       });
//       return true;
//     })
//     .run();
// };

const moveLineDown: KeyboardShortcutCommand = ({ editor }) => {
  const { from, to, $anchor } = editor.state.selection;
  const lineStart = _findLineStartBefore(from, $anchor);
  const lineEnd = _findLineEndAfter(to, $anchor);
  if (lineEnd + 1 > $anchor.end()) {
    // 末尾まで来ていたら何もしない
    return true;
  }
  const nextLineEnd = _findLineEndAfter(lineEnd + 1, $anchor);
  return editor
    .chain()
    .command(({ tr }) => {
      tr.insertText('\n', nextLineEnd);
      tr.replace(
        nextLineEnd + 1,
        nextLineEnd + 1,
        editor.state.doc.slice(lineStart, lineEnd, false),
      );
      return true;
    })
    .deleteRange({ from: lineStart, to: lineEnd + 1 })
    .setTextSelection({
      from: lineStart + (nextLineEnd - lineEnd) + (from - lineStart),
      to: lineStart + (nextLineEnd - lineEnd) + (to - lineStart),
    })
    .run();
};

const moveLineUp: KeyboardShortcutCommand = ({ editor }) => {
  const { from, to, $anchor } = editor.state.selection;
  const lineStart = _findLineStartBefore(from, $anchor);
  const lineEnd = _findLineEndAfter(to, $anchor);
  if (lineStart <= $anchor.start()) {
    // 先頭まで来ていたら何もしない
    return true;
  }
  const prevLineStart = _findLineStartBefore(lineStart - 1, $anchor);
  const insertLineSize = lineEnd - lineStart;
  return editor
    .chain()
    .command(({ tr }) => {
      tr.replace(prevLineStart, prevLineStart, editor.state.doc.slice(lineStart, lineEnd, false));
      tr.insertText('\n', prevLineStart + insertLineSize);
      return true;
    })
    .deleteRange({ from: lineStart + insertLineSize, to: lineEnd + insertLineSize + 1 })
    .setTextSelection({
      from: prevLineStart + (from - lineStart),
      to: prevLineStart + (to - lineStart),
    })
    .run();
};

const copyLineDown: KeyboardShortcutCommand = ({ editor }) => {
  const { from, to, $anchor } = editor.state.selection;
  const lineStart = _findLineStartBefore(from, $anchor);
  const lineEnd = _findLineEndAfter(to, $anchor);
  return editor
    .chain()
    .command(({ tr }) => {
      tr.insertText('\n', lineEnd);
      tr.replace(lineEnd + 1, lineEnd + 1, editor.state.doc.slice(lineStart, lineEnd, false));
      return true;
    })
    .setTextSelection({
      from: from + (lineEnd - lineStart),
      to: to + (lineEnd - lineStart),
    })
    .run();
};

const copyLineUp: KeyboardShortcutCommand = ({ editor }) => {
  const { from, to, $anchor } = editor.state.selection;
  const lineStart = _findLineStartBefore(from, $anchor);
  const lineEnd = _findLineEndAfter(to, $anchor);
  return editor
    .chain()
    .command(({ tr }) => {
      tr.replace(lineStart, lineStart, editor.state.doc.slice(lineStart, lineEnd, false));
      tr.insertText('\n', lineStart + (lineEnd - lineStart));
      return true;
    })
    .setTextSelection({
      from,
      to,
    })
    .run();
};

const expandLineSelection: KeyboardShortcutCommand = ({ editor }) => {
  const { from, to, $anchor } = editor.state.selection;
  const lineStart = _findLineStartBefore(from, $anchor);
  const lineEnd = _findLineEndAfter(to, $anchor);
  return editor.commands.setTextSelection({ from: lineStart, to: lineEnd });
};

/**
 * vscode のショートカットをなるべく網羅するようにしていく
 * https://code.visualstudio.com/docs/getstarted/keybindings#_basic-editing
 * (複数の離れた箇所にカーソルを当てるとかは仕様上無理なので諦める)
 */
export const getCodeEditorKeyboardShortcutBindings = (
  options: {
    isSql?: boolean;
    enableExitSqlBlockBody?: boolean;
    enableExitCodeBlock?: boolean;
  } = {},
): KeyboardShortcutBindings => {
  const bindings: KeyboardShortcutBindings = {};
  registerShortcuts(bindings, SHORTCUT_KEYS.INDENT_LINES, indentLines);
  registerShortcuts(bindings, SHORTCUT_KEYS.OUTDENT_LINES, outdentLines);
  registerShortcuts(bindings, SHORTCUT_KEYS.MOVE_START_OF_CODE, moveToAnchorStart);
  registerShortcuts(bindings, SHORTCUT_KEYS.MOVE_END_OF_CODE, moveToAnchorEnd);
  registerShortcuts(bindings, SHORTCUT_KEYS.DELETE_LINES, deleteLines);
  registerShortcuts(bindings, SHORTCUT_KEYS.MOVE_LINE_DOWN, moveLineDown);
  registerShortcuts(bindings, SHORTCUT_KEYS.MOVE_LINE_UP, moveLineUp);
  registerShortcuts(bindings, SHORTCUT_KEYS.COPY_LINE_DOWN, copyLineDown);
  registerShortcuts(bindings, SHORTCUT_KEYS.COPY_LINE_UP, copyLineUp);
  registerShortcuts(bindings, SHORTCUT_KEYS.EXPAND_LINE_SELECTION, expandLineSelection);

  const additionalBindings: KeyboardShortcutBindings = {};
  if (options.isSql) {
    registerShortcuts(additionalBindings, SHORTCUT_KEYS.TOGGLE_SQL_COMMENT, toggleSqlComment);
  }
  if (options.enableExitCodeBlock) {
    // MEMO: tiptap で code タグの node は特殊で ArrowDown でデフォルトでブロック外に移動できないのでその対応
    additionalBindings['ArrowDown'] = exitCodeBlockIfAtEnd;
  }
  if (options.enableExitSqlBlockBody) {
    // MEMO: 上記の更に特殊なケースへの対応版
    additionalBindings['ArrowDown'] = preventArrowDownMoveOutOfCodeBlock;
  }
  return mergeKeyboardShortcutBindings(
    {
      Tab: insertIndentByTab,
      'Shift-Tab': removeIndentByTab,
      'Control-a': moveToLineStart,
      'Control-e': moveToLineEnd,
      Enter: enterWithIndent,
      Backspace: chainKeyboardShortcutsCommand(
        createCommandSelectBracketPairToDelete(BRACKET_PAIRS),
        createCommandSelectQuotePairToDelete(QUOTES),
        deleteIndentMultipleSpaces,
        baskacpeOnNodeSpacer,
      ),
      'Mod-x': cutLineIfSelectionEmpty,
      'Mod-c': copyLineIfSelectionEmpty,
      // 'Mod-Enter': insertLineBelow,  // FIXME: SQL実行と衝突
      // 'Mod-Shift-Enter': insertLineAbove,  // Mod-Enter と対っぽいのでコメントアウト
    },
    createBracketShortcutBindings(BRACKET_PAIRS),
    createQuoteShortcutBindings(QUOTES),
    bindings,
    additionalBindings,
  );
};
