/* eslint-disable import/order */

import { createApp } from 'vue';

import App from './App.vue';
const app = createApp(App);

import { createHead } from '@unhead/vue';
const head = createHead();
app.use(head);

import ArcoVue, { addI18nMessages, useLocale, Modal, Drawer } from '@arco-design/web-vue';
import enUS from '@arco-design/web-vue/es/locale/lang/en-us';
import '@cdm/arco.less';
app.use(ArcoVue);
addI18nMessages({ enUS });
useLocale('enUS');

// MEMO: a-tooltip の挙動を無理やり上書き
// FIXME: Component "ATooltip" has already been registered in target app. の warning 出ないようにしたい
import Tooltip from '@cdm/components/arco/Tooltip.vue';
app.component('ATooltip', Tooltip);

import '@cdm/assets/styles/print.css';
// @deprecated 削除する
import '@cdm/assets/styles/css-vars.css';

Modal._context = app._context;
Drawer._context = app._context;

import { setTooltipContext } from '@cdm/libs/s2-table';
setTooltipContext(app._context);

import router from './router';
import { initLogger } from '../../utils/logger';
initLogger(app, router);
app.use(router);

import fa from '@cdm/components/fa/fa.vue';
app.component('Fa', fa);

import '@cdm/utils/loadicon';

// @ts-expect-error: @types 定義がない
import Vue3Resize from 'vue3-resize';
import 'vue3-resize/dist/vue3-resize.css';
app.use(Vue3Resize);

// https://github.com/ueberdosis/tiptap/issues/1719
// @ts-expect-error: todo
app.config.unwrapInjectedRef = true;

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import relativeTime from 'dayjs/plugin/relativeTime';
import duration from 'dayjs/plugin/duration';
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);
dayjs.extend(duration);

import { VueQueryPlugin, QueryCache } from '@tanstack/vue-query';
import type { VueQueryPluginOptions } from '@tanstack/vue-query';
import { Message } from '@cdm/components/arco/Message';

const vueQueryPluginOptions: VueQueryPluginOptions = {
  queryClientConfig: {
    defaultOptions: {
      queries: {
        gcTime: 1000 * 60 * 10,
        staleTime: 1000 * 60 * 5,

        // 400系エラーの場合リトライをスキップする
        retry: (failureCount, error: any) => {
          if (400 <= error.status && error.status < 500) {
            return false;
          }
          // それ以外の場合は3回までリトライ
          return failureCount < 3;
        },
      },
    },
    queryCache: new QueryCache({
      onError: (error, query: any) => {
        if (query?.meta?.errorMessage) {
          Message.error(query.meta.errorMessage);
        }
      },
    }),
  },
};
app.use(VueQueryPlugin, vueQueryPluginOptions);

import Hotkey from '@cdm/libs/hotkey';
app.use(Hotkey);

import tracker from '../../tracker';
tracker.init(app);

app.mount('#app');
