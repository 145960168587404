import { type ApiRoutes, getApiEntries } from '../common/api';

export class ApiError extends Error {
  status: number;
  statusText: string;
  reqId: string;

  constructor(message: string, status: number, statusText: string, reqId: string) {
    super(message);
    this.status = status;
    this.reqId = reqId;
    this.statusText = statusText;
  }
}

export function getApiClient<TApi>(
  routes: ApiRoutes<TApi>,
  config: {
    baseUrl: string;
    getContext: () => { wsId: string };
    getToken: () => Promise<string>;
  },
): TApi {
  const api = {} as any;

  for (const { name, path } of getApiEntries(routes)) {
    api[name] = async (...args: any[]) => {
      let url = `${config.baseUrl}${path}`;
      const context = config.getContext();
      url += `?ws=${context.wsId}`;

      const token = await config.getToken();

      const res = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ d: args }),
      });
      if (res.ok) {
        const txt = await res.text();
        try {
          return JSON.parse(txt);
        } catch (err: any) {
          return txt;
        }
      } else {
        const err = await getError(res);
        throw err;
      }
    };
  }
  return api;
}

/* getApiClient の呼び出し時に wsId を固定にする版 */
export function getApiClientByWsId<TApi>(
  routes: ApiRoutes<TApi>,
  config: {
    baseUrl: string;
    wsId: string;
    getToken: () => Promise<string>;
  },
): TApi {
  return getApiClient(routes, {
    baseUrl: config.baseUrl,
    getContext: () => ({ wsId: config.wsId }),
    getToken: config.getToken,
  });
}

export const getError = async (res: Response): Promise<any> => {
  const errObj: any = await (async function () {
    const txt = await res.text();
    try {
      return JSON.parse(txt);
    } catch (err: any) {
      return { message: txt };
    }
  })();

  if (Math.floor(res.status / 100) === 4) {
    if (res.status === 403) {
      return new ApiError(errObj.message, res.status, res.statusText, errObj.reqId);
    }
    return errObj;
  } else {
    return new ApiError(errObj.message, res.status, res.statusText, errObj.reqId);
  }
};
