import type { AccountSetting } from '@cdm/@generated-account-client/shared-types';
import { reactive } from 'vue';
import { accountClient } from '@cdm/clients/AccountClient';

export class SettingStore {
  private state: {
    setting: AccountSetting | null;
    isFetching: boolean;
  };

  constructor() {
    this.state = reactive({
      setting: null,
      isFetching: false,
    });
  }

  public async fetchSetting() {
    this.state.isFetching = true;
    try {
      const setting = await accountClient.accountSetting_get({}, {});
      this.state.setting = setting;
    } catch (err) {
      console.error(err);
    } finally {
      this.state.isFetching = false;
    }
  }

  public get isFetching() {
    return this.state.isFetching;
  }

  public get keyboardShortcuts() {
    return this.state.setting?.keyboard_shortcuts;
  }
}
