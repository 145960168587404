import moo from 'moo';

export const TOKEN_TYPES = {
  FIELD: 'FIELD',
  DIRECTION: 'DIRECTION',
  WHITESPACE: 'WHITESPACE',
  OTHER: 'OTHER',
} as const;

export type TokenType = typeof TOKEN_TYPES[keyof typeof TOKEN_TYPES];

// moo の型定義に RegExp[] が欠けている…
type TokenizeRules = {
  [token in TokenType]?:
    | RegExp
    | RegExp[]
    | string
    | string[]
    | moo.Rule
    | moo.Rule[]
    | moo.ErrorRule
    | moo.FallbackRule;
};

const TOKENIZE_RULES: TokenizeRules = {
  FIELD: {
    match: /[a-zA-Z_]+/,
    type: moo.keywords({
      DIRECTION: ['ASC', 'DESC', 'Asc', 'Desc', 'asc', 'desc'],
    }),
  },
  WHITESPACE: { match: /\s+/, lineBreaks: true },
  OTHER: /[^\s]+/, // その他すべてにマッチ
};

const lexer = moo.compile(TOKENIZE_RULES as moo.Rules);

export type OrderParseResult = {
  field: string;
  direction?: 'ASC' | 'DESC';
  isValid?: boolean;
};

export const parseOrder = (orderString: string): OrderParseResult => {
  const res: OrderParseResult = {
    field: '',
  };
  if (!orderString) {
    return res;
  }
  lexer.reset(orderString);
  for (const token of lexer) {
    if (token.type === TOKEN_TYPES.WHITESPACE) {
      continue; // ignore
    }
    if (!res.field) {
      if (token.type === TOKEN_TYPES.FIELD) {
        res.field = token.value;
        res.isValid = true;
        continue;
      } else {
        return res;
      }
    }
    if (res.field && !res.direction) {
      if (token.type === TOKEN_TYPES.DIRECTION) {
        res.direction = token.value.toUpperCase() as 'ASC' | 'DESC';
        res.isValid = true;
        continue;
      } else {
        res.isValid = false;
        return res;
      }
    }
    if (res.field && res.direction) {
      // すべて埋まっている状態で後続に更になにか入力されている場合
      res.isValid = false;
      return res;
    }
  }
  return res;
};
