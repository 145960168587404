import type { AssistantMessageNodeJson, AssistantMessageNodeName } from '../types/my-assistant';
import type { NodeAttributeDef } from '../types/notebook';

export const ASSISTANT_MESSAGE_NODE_NAMES = {
  DOC: 'doc',
  TEXT: 'text',
  PARAGRAPH: 'paragraph',
  HEADING: 'heading',
  CODE_BLOCK: 'codeBlock',
  TABLE: 'table',
  TABLE_ROW: 'tableRow',
  TABLE_HEADER: 'tableHeader',
  TABLE_CELL: 'tableCell',
  BLOCKQUOTE: 'blockquote',
  LIST: 'list',
  HORIZONTAL_RULE: 'horizontalRule',

  // MEMO: node の schema 的に notebook と互換しないものはあえて名前を変える
  SQL_CODE_BLOCK: 'sqlCodeBlock',
  SQL_CODE_SQL_BLOCK_REF: 'sqlCodeSqlBlockRef',
  SQL_CODE_SQL_TABLE_REF: 'sqlCodeSqlTableRef',
  SQL_CODE_SQL_QUERY_REF: 'sqlCodeSqlQueryRef',
  SQL_CODE_SQL_PARAM_REF: 'sqlCodeSqlParamRef',
  SQL_QUERY_RESULT: 'sqlQueryResult',
  SQL_TABLE_METADATA: 'sqlTableMetadata',
  CHART_CODE_BLOCK: 'chartCodeBlock',
} as const;

export const ASSISTANT_MESSAGE_NODE_GROUPS = {
  BLOCK: 'block',
  INLINE: 'inline',
} as const;

export const ASSISTANT_MESSAGE_CODE_BLOCK_CONTENTS = [
  ASSISTANT_MESSAGE_NODE_NAMES.TEXT,
  ASSISTANT_MESSAGE_NODE_NAMES.SQL_CODE_SQL_BLOCK_REF,
  ASSISTANT_MESSAGE_NODE_NAMES.SQL_CODE_SQL_TABLE_REF,
  ASSISTANT_MESSAGE_NODE_NAMES.SQL_CODE_SQL_QUERY_REF,
  ASSISTANT_MESSAGE_NODE_NAMES.SQL_CODE_SQL_PARAM_REF,
] as const;

export const ASSISTANT_MESSAGE_NODE_DEFS: {
  [nodeName in AssistantMessageNodeName]: {
    topNode?: boolean;
    content?: string;
    atom?: boolean;
    marks?: string;
    inline?: boolean;
    group?: string;
    defining?: boolean;
    isolating?: boolean;
    selectable?: boolean;
    code?: boolean;
    priority?: number;
    htmlTagName?: 'div' | 'code' | 'span';
    attributesDef?: { [key: string]: NodeAttributeDef };
  };
} = {
  [ASSISTANT_MESSAGE_NODE_NAMES.DOC]: {
    // tiptap 定義をそのまま利用
  },
  [ASSISTANT_MESSAGE_NODE_NAMES.TEXT]: {
    // tiptap 定義をそのまま利用
  },
  [ASSISTANT_MESSAGE_NODE_NAMES.PARAGRAPH]: {
    // tiptap 定義をそのまま利用
  },
  [ASSISTANT_MESSAGE_NODE_NAMES.HEADING]: {
    // 他は tiptap 定義をそのまま利用
    content: 'text*',
  },
  [ASSISTANT_MESSAGE_NODE_NAMES.CODE_BLOCK]: {
    // 他は tiptap 定義をそのまま利用
    isolating: true,
  },
  [ASSISTANT_MESSAGE_NODE_NAMES.TABLE]: {
    // tiptap 定義をそのまま利用
  },
  [ASSISTANT_MESSAGE_NODE_NAMES.TABLE_ROW]: {
    // tiptap 定義をそのまま利用
  },
  [ASSISTANT_MESSAGE_NODE_NAMES.TABLE_HEADER]: {
    // 他は tiptap 定義をそのまま利用
    content: ASSISTANT_MESSAGE_NODE_NAMES.PARAGRAPH,
  },
  [ASSISTANT_MESSAGE_NODE_NAMES.TABLE_CELL]: {
    // 他は tiptap 定義をそのまま利用
    content: ASSISTANT_MESSAGE_NODE_NAMES.PARAGRAPH,
  },
  [ASSISTANT_MESSAGE_NODE_NAMES.BLOCKQUOTE]: {
    // 他は tiptap 定義をそのまま利用
    content: 'paragraph+',
  },
  [ASSISTANT_MESSAGE_NODE_NAMES.LIST]: {
    group: 'block',
    defining: true,
    content: ASSISTANT_MESSAGE_NODE_NAMES.PARAGRAPH,
    attributesDef: {
      kind: {
        datatype: 'STRING',
        default: 'BULLET',
      },
      depth: {
        datatype: 'NUMBER',
        default: 0,
      },
    },
  },
  [ASSISTANT_MESSAGE_NODE_NAMES.HORIZONTAL_RULE]: {
    // tiptap 定義をそのまま利用
  },
  [ASSISTANT_MESSAGE_NODE_NAMES.SQL_CODE_BLOCK]: {
    isolating: true,
    content: `(${ASSISTANT_MESSAGE_CODE_BLOCK_CONTENTS.join(' | ')})*`,
    attributesDef: {
      sqlCodeId: {
        datatype: 'STRING',
      },
      sourceSqlId: {
        datatype: 'STRING',
      },
    },
  },
  [ASSISTANT_MESSAGE_NODE_NAMES.SQL_CODE_SQL_BLOCK_REF]: {
    inline: true,
    selectable: true,
    atom: true,
    attributesDef: {
      annotAttrs: {
        datatype: 'JSON',
        default: {},
      },
      displaySql: {
        datatype: 'STRING',
        default: '',
      },
    },
  },
  [ASSISTANT_MESSAGE_NODE_NAMES.SQL_CODE_SQL_TABLE_REF]: {
    inline: true,
    selectable: true,
    atom: true,
    attributesDef: {
      annotAttrs: {
        datatype: 'JSON',
        default: {},
      },
      displaySql: {
        datatype: 'STRING',
        default: '',
      },
    },
  },
  [ASSISTANT_MESSAGE_NODE_NAMES.SQL_CODE_SQL_QUERY_REF]: {
    inline: true,
    selectable: true,
    atom: true,
    attributesDef: {
      annotAttrs: {
        datatype: 'JSON',
        default: {},
      },
      displaySql: {
        datatype: 'STRING',
        default: '',
      },
    },
  },
  [ASSISTANT_MESSAGE_NODE_NAMES.SQL_CODE_SQL_PARAM_REF]: {
    inline: true,
    selectable: true,
    atom: true,
    attributesDef: {
      annotAttrs: {
        datatype: 'JSON',
        default: {},
      },
      displaySql: {
        datatype: 'STRING',
        default: '',
      },
    },
  },
  [ASSISTANT_MESSAGE_NODE_NAMES.SQL_QUERY_RESULT]: {
    isolating: true,
    attributesDef: {
      queryResultId: {
        datatype: 'STRING',
      },
      sqlCodeId: {
        datatype: 'STRING',
      },
      jobId: {
        datatype: 'STRING',
      },
    },
  },
  [ASSISTANT_MESSAGE_NODE_NAMES.SQL_TABLE_METADATA]: {
    isolating: true,
    attributesDef: {
      tableMetadataId: {
        datatype: 'STRING',
      },
      tableResourceId: {
        datatype: 'STRING',
      },
    },
  },
  [ASSISTANT_MESSAGE_NODE_NAMES.CHART_CODE_BLOCK]: {
    isolating: true,
    attributesDef: {
      chartCodeId: {
        datatype: 'STRING',
      },
      sqlCodeId: {
        datatype: 'STRING',
      },
      sourceChartId: {
        datatype: 'STRING',
      },
    },
  },
} as const;

export const ASSISTANT_MESSAGE_MARK_NAMES = {
  BOLD: 'bold',
  CODE: 'code',
  ITALIC: 'italic',
  LINK: 'link',
} as const;

export const ASSISTANT_MESSAGE_TEXT_MARK_NAMES = [
  ASSISTANT_MESSAGE_MARK_NAMES.BOLD,
  ASSISTANT_MESSAGE_MARK_NAMES.CODE,
  ASSISTANT_MESSAGE_MARK_NAMES.ITALIC,
  ASSISTANT_MESSAGE_MARK_NAMES.LINK,
] as const;

export const ASSISTANT_MESSAGE_EXTENSION_NAMES = {
  SUGGESTION_SUGGESTION: 'suggestionSuggestion',
  SUGGESTION_TABLE_REF: 'suggestionTableRef',
  SUGGESTION_SQL_BLOCK_REF: 'suggestionSqlBlockRef',
  SLASH_COMMAND: 'slashCommand',
  SLASH_COMMAND_TABLE_METADATA: 'TableMetadataSlashCommand',
  SLASH_COMMAND_NOTEBOOK_SQL: 'NotebookSqlSlashCommand',
  SQL_CODE_BLOCK_SYNTAX_HIGHLIGHT: 'sqlCodeBlockSyntaxHighlight',
} as const;

export const ASSISTANT_MESSAGE_EDITOR_DEFAULT_VALUE: AssistantMessageNodeJson = {
  type: 'doc',
  content: [{ type: 'paragraph' }],
};

export const ASSISTANT_ROLES = {
  ANALYST: 'ANALYST',
  SUPPORT: 'SUPPORT',
} as const;

export const ASSISTANT_ROLE_OPTIONS = [
  {
    label: 'AI Analyst',
    value: ASSISTANT_ROLES.ANALYST,
    description:
      'Assists with SQL query generation and correction, and creates chart visualizations',
  },
  {
    label: 'AI Support',
    value: ASSISTANT_ROLES.SUPPORT,
    description: 'Answers questions about using Codatum and provides guidance on its features',
  },
] as const;

export const ASSISTANT_MESSAGE_SPECIAL_CODE_LANGUAGES = {
  SQL: 'sql',
  SQL_QUERY_RESULT: 'sql-query-result',
  SQL_TABLE_METADATA: 'sql-table-metadata',
  CHART_CODE: 'chart-code',
} as const;

export const ASSISTANT_MESSAGE_ANNOTATION_LABEL = {
  START: 'START-ANNOTATION',
  END: 'END-ANNOTATION',
} as const;

export const TOKEN_USAGE_SCOPE_KINDS = {
  WORKSPACE: 'WORKSPACE',
  ACCOUNT: 'ACCOUNT',
} as const;

export const FEEDBACK_RATING_VALUES = {
  GOOD: 'GOOD',
  BAD: 'BAD',
} as const;

export const ASSISTANT_EXECUTED_JOB_TYPES = {
  SQL_CODE: 'SQL_CODE',
  CHART_CODE: 'CHART_CODE',
} as const;
