import { SYNTAX_HIGHLIGHT_TOKEN_TYPES } from './tokenizer';
import type { SyntaxHighlightTokenType } from './tokenizer';

export const INVISIBLE_CHARACTERS_CLASS_MAP: { [key: string]: string } = {
  '\t': 'ws-Tab',
  ' ': 'ws-Space',
  '　': 'ws-FullWidthSpace',
};

export const HIGHLIGHT_TOKENS: SyntaxHighlightTokenType[] = [
  SYNTAX_HIGHLIGHT_TOKEN_TYPES.COMMENT,
  SYNTAX_HIGHLIGHT_TOKEN_TYPES.LITERAL,
  SYNTAX_HIGHLIGHT_TOKEN_TYPES.KEYWORD,
  SYNTAX_HIGHLIGHT_TOKEN_TYPES.FUNCTION,
  SYNTAX_HIGHLIGHT_TOKEN_TYPES.REFERENCE,
];
