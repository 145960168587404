import { getColorFromCssVariable } from '@cdm/utils/cssVar';

export const COLOR_NAMES = {
  PRIMARY: 'PRIMARY',
  SUCCESS: 'SUCCESS',
  WARN: 'WARN',
  DANGER: 'DANGER',

  WHITE: 'WHITE',

  // チャート用
  SERIES1: 'SERIES1',
  SERIES2: 'SERIES2',
  SERIES3: 'SERIES3',
  SERIES4: 'SERIES4',
  SERIES5: 'SERIES5',
  SERIES6: 'SERIES6',
  SERIES7: 'SERIES7',
  SERIES8: 'SERIES8',
  SERIES9: 'SERIES9',
  SERIES10: 'SERIES10',
  SERIES11: 'SERIES11',
  SERIES12: 'SERIES12',
} as const;
export type ColorName = typeof COLOR_NAMES[keyof typeof COLOR_NAMES];

export const COLOR_CATEGORIES = {
  SEMANTIC: 'SEMANTIC',
  SERIES: 'SERIES',
} as const;
export type ColorCategory = typeof COLOR_CATEGORIES[keyof typeof COLOR_CATEGORIES];

export const COLOR_METADATA: {
  [key in ColorName]: { category: ColorCategory; description: string };
} = {
  PRIMARY: {
    category: COLOR_CATEGORIES.SEMANTIC,
    description: 'Theme Color',
  },
  SUCCESS: {
    category: COLOR_CATEGORIES.SEMANTIC,
    description: 'Success',
  },
  WARN: {
    category: COLOR_CATEGORIES.SEMANTIC,
    description: 'Warning',
  },
  DANGER: {
    category: COLOR_CATEGORIES.SEMANTIC,
    description: 'Danger / Critical',
  },
  WHITE: {
    category: COLOR_CATEGORIES.SEMANTIC,
    description: 'White',
  },
  SERIES1: {
    category: COLOR_CATEGORIES.SERIES,
    description: '',
  },
  SERIES2: {
    category: COLOR_CATEGORIES.SERIES,
    description: '',
  },
  SERIES3: {
    category: COLOR_CATEGORIES.SERIES,
    description: '',
  },
  SERIES4: {
    category: COLOR_CATEGORIES.SERIES,
    description: '',
  },
  SERIES5: {
    category: COLOR_CATEGORIES.SERIES,
    description: '',
  },
  SERIES6: {
    category: COLOR_CATEGORIES.SERIES,
    description: '',
  },
  SERIES7: {
    category: COLOR_CATEGORIES.SERIES,
    description: '',
  },
  SERIES8: {
    category: COLOR_CATEGORIES.SERIES,
    description: '',
  },
  SERIES9: {
    category: COLOR_CATEGORIES.SERIES,
    description: '',
  },
  SERIES10: {
    category: COLOR_CATEGORIES.SERIES,
    description: '',
  },
  SERIES11: {
    category: COLOR_CATEGORIES.SERIES,
    description: '',
  },
  SERIES12: {
    category: COLOR_CATEGORIES.SERIES,
    description: '',
  },
};

export type HexColor = string;
type HexColors = { [key in ColorName]: HexColor };

let _hexColors: HexColors | undefined;
const getHexColors = (): HexColors => {
  if (!_hexColors) {
    // MEMO: CSS変数から色を取得（キャッシュして使っており、dark/light切替時はページリロードされる前提）
    _hexColors = {
      PRIMARY: getColorFromCssVariable('--primary-6', { toHex: true }),

      SUCCESS: getColorFromCssVariable('--success-6', { toHex: true }),
      WARN: getColorFromCssVariable('--warning-6', { toHex: true }),
      DANGER: getColorFromCssVariable('--danger-6', { toHex: true }),

      WHITE: '#ffffff',

      SERIES1: getColorFromCssVariable('--cdm-color-chart-1', { toHex: true }),
      SERIES2: getColorFromCssVariable('--cdm-color-chart-2', { toHex: true }),
      SERIES3: getColorFromCssVariable('--cdm-color-chart-3', { toHex: true }),
      SERIES4: getColorFromCssVariable('--cdm-color-chart-4', { toHex: true }),
      SERIES5: getColorFromCssVariable('--cdm-color-chart-5', { toHex: true }),
      SERIES6: getColorFromCssVariable('--cdm-color-chart-6', { toHex: true }),
      SERIES7: getColorFromCssVariable('--cdm-color-chart-7', { toHex: true }),
      SERIES8: getColorFromCssVariable('--cdm-color-chart-8', { toHex: true }),
      SERIES9: getColorFromCssVariable('--cdm-color-chart-9', { toHex: true }),
      SERIES10: getColorFromCssVariable('--cdm-color-chart-10', { toHex: true }),
      SERIES11: getColorFromCssVariable('--cdm-color-chart-11', { toHex: true }),
      SERIES12: getColorFromCssVariable('--cdm-color-chart-12', { toHex: true }),
    };
  }
  return _hexColors;
};

export const getHexColor = (colorName: ColorName | undefined | null): HexColor | undefined => {
  if (!colorName) return undefined;
  return getHexColors()[colorName] || undefined;
};

export const getHexColorPalette = (): HexColor[] => {
  const c = getHexColors();
  return [
    c.SERIES1,
    c.SERIES2,
    c.SERIES3,
    c.SERIES4,
    c.SERIES5,
    c.SERIES6,
    c.SERIES7,
    c.SERIES8,
    c.SERIES9,
    c.SERIES10,
    c.SERIES11,
    c.SERIES12,
  ];
};

export const getColorPalette = (): ColorName[] => {
  return [
    COLOR_NAMES.SERIES1,
    COLOR_NAMES.SERIES2,
    COLOR_NAMES.SERIES3,
    COLOR_NAMES.SERIES4,
    COLOR_NAMES.SERIES5,
    COLOR_NAMES.SERIES6,
    COLOR_NAMES.SERIES7,
    COLOR_NAMES.SERIES8,
    COLOR_NAMES.SERIES9,
    COLOR_NAMES.SERIES10,
    COLOR_NAMES.SERIES11,
    COLOR_NAMES.SERIES12,
  ];
};
