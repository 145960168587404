import { ApiClient } from '../@generated-account-client/client';
import { createFetcher } from './fetcher';

import { getToken } from '@cdm/libs/auth';

export const accountClient = new ApiClient(
  createFetcher({
    getToken,
  }),
);
