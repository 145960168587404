import type {
  GetUserPermissionsResponse,
  AuthUserPermission as _AuthUserPermission,
  TWorkspaceAccountRole,
} from '@cdm/@generated-account-client/shared-types';
import { reactive } from 'vue';
import type { Group } from '@cdm/@generated-account-client/client';
import type { NotebookSharedRole } from '@cdm/@shared-server-notebook/types/notebook';
import type { ReportSharedRole } from '@cdm/@shared-server-notebook/types/report';
import { NOTEBOOK_SHARED_ROLES } from '@cdm/@shared-server-notebook/constants/notebook';
import {
  CONNECTION_ACCESS_LEVELS,
  type ConnectionAccessLevel,
} from '@cdm/domains/connection/shared/constants';
import { accountClient } from '@cdm/clients/AccountClient';

export type WorkspaceRole = TWorkspaceAccountRole['role'];
export type GroupPermission = NonNullable<Group['permissions']>[number];

export const WORKSPACE_ROLES = {
  OWNER: 'owner',
  EDITOR: 'editor',
  VIEWER: 'viewer',
  GUEST: 'guest',
} as const;

export const WORKSPACE_ROLE_OPTIONS: {
  value: WorkspaceRole;
  label: string;
  description: string;
}[] = [
  {
    value: WORKSPACE_ROLES.OWNER,
    label: 'Owner',
    description: 'Can change workspace settings, invite new members to join workspace.',
  },
  {
    value: WORKSPACE_ROLES.EDITOR,
    label: 'Editor',
    description:
      'Can edit notebooks and create connections. Cannot change workspace settings and invite new members.',
  },
  {
    value: WORKSPACE_ROLES.VIEWER,
    label: 'Viewer',
    description: 'Can browse notebooks. Cannot edit notebooks or create connections.',
  },
  {
    value: WORKSPACE_ROLES.GUEST,
    label: 'Guest',
    description: 'Can browse only shared reports.',
  },
];

export const WORKSPACE_ROLE_OPTIONS_EXCEPT_GUEST = WORKSPACE_ROLE_OPTIONS.filter(
  r => r.value !== WORKSPACE_ROLES.GUEST,
);

export const getWorkspaceRoleDisplayName = (role: WorkspaceRole) => {
  return WORKSPACE_ROLE_OPTIONS.find(r => r.value === role)?.label ?? role;
};

export const getWorkspaceRoleDescription = (role: WorkspaceRole) => {
  return WORKSPACE_ROLE_OPTIONS.find(r => r.value === role)?.description;
};

export type AuthUserAction = _AuthUserPermission['act'];
export type AuthUserObject = _AuthUserPermission['obj'] | `ws/conn/${string}` | `ws/ts/${string}`;

type AuthUserPermission = {
  act: AuthUserAction;
  obj: AuthUserObject;
};

// MEMO: Group 経由の permission では teamspace.owner とか connection.owner は付与しない
const RESOURCE_ROLES = {
  TEAMSPACE: {
    OWNER: 'teamspace.owner',
    EDITOR: 'teamspace.editor',
    VIEWER: 'teamspace.viewer',
  },
  CONNECTION: {
    OWNER: 'connection.owner',
    USER: 'connection.user',
    VIEWER: 'connection.viewer',
  },
} as const;
export type ResourceType = keyof typeof RESOURCE_ROLES;
export type TeamspaceRole = typeof RESOURCE_ROLES.TEAMSPACE[keyof typeof RESOURCE_ROLES.TEAMSPACE];
export type ConnectionRole =
  typeof RESOURCE_ROLES.CONNECTION[keyof typeof RESOURCE_ROLES.CONNECTION];
export type ResourceRole = TeamspaceRole | ConnectionRole;
const RESOURCE_ROLE_CAN_LABEL: {
  [key in ResourceRole | NotebookSharedRole | ReportSharedRole]: string;
} = {
  'connection.owner': 'Owner',
  'connection.user': 'User',
  'connection.viewer': 'Viewer',
  'teamspace.owner': 'Owner',
  'teamspace.editor': 'Editor',
  'teamspace.viewer': 'Viewer',
  'notebook.editor': 'Editor',
  'notebook.viewer': 'Viewer',
  'report.viewer': 'Viewer',
};
const RESOURCE_ROLE_CAN_DESCRIPTION: {
  [key in ResourceRole | NotebookSharedRole | ReportSharedRole]: string;
} = {
  'connection.owner': 'Can manage connection',
  'connection.user': 'Can execute queries and download query results',
  'connection.viewer': 'Can view query results and tables',
  'teamspace.owner': 'Can manage teamspace',
  'teamspace.editor': 'Can edit notebooks and folders in teamspace',
  'teamspace.viewer': 'Can view notebooks and folders in teamspace',
  'notebook.editor': 'Can edit notebook',
  'notebook.viewer': 'Can view notebook',
  'report.viewer': 'Can view report',
};

export const getResourceRoleCanLabel = (
  role: ResourceRole | NotebookSharedRole | ReportSharedRole,
): string => {
  return RESOURCE_ROLE_CAN_LABEL[role] ?? '(Unknown role)';
};

export const ENFORCE_OBJECT_GROUP_PREFIX = 'ws/grp/';
export const ENFORCE_OBJECT_TEAMSPACE_PREFIX = 'ws/ts/';
export const ENFORCE_OBJECT_CONNECTION_PREFIX = 'ws/conn/';

export const parseResourceObject = (
  obj: AuthUserObject,
): { resourceType: ResourceType; resourceId: string } | null => {
  if (obj.startsWith(ENFORCE_OBJECT_TEAMSPACE_PREFIX)) {
    return {
      resourceType: 'TEAMSPACE',
      resourceId: obj.split('/').pop() as string,
    };
  } else if (obj.startsWith(ENFORCE_OBJECT_CONNECTION_PREFIX)) {
    return {
      resourceType: 'CONNECTION',
      resourceId: obj.split('/').pop() as string,
    };
  }
  return null;
};

export class PermissionStore {
  private state: {
    wsId: string | null;
    role: GetUserPermissionsResponse['role'] | null;
    groupIds: GetUserPermissionsResponse['group_ids'];
    permissions: AuthUserPermission[];
    isAdmin: boolean;
  };

  constructor() {
    this.state = reactive({
      wsId: null,
      role: null,
      groupIds: [],
      permissions: [],
      isAdmin: false,
    });
  }

  public async fetchPermissions(wsId: string) {
    try {
      const { role, permissions, is_admin, group_ids } =
        await accountClient.authorize_getUserPermissions({}, { ws: wsId });
      this.state.wsId = wsId;
      this.state.role = role;
      this.state.groupIds = group_ids ?? [];
      this.state.permissions = permissions ?? [];
      this.state.isAdmin = is_admin;
    } catch (err) {
      this.state.wsId = null;
      this.state.role = null;
      this.state.groupIds = [];
      this.state.permissions = [];
      this.state.isAdmin = false;
      console.error(err);
    }
  }

  public async reloadPermissions() {
    if (this.state.wsId) {
      await this.fetchPermissions(this.state.wsId);
    }
  }

  public getWorkspaceRoleDisplay(): string {
    return getWorkspaceRoleDisplayName(this.state.role!);
  }

  public isBelongsToGroup(groupId: string): boolean {
    return Boolean(this.state.groupIds?.includes(groupId));
  }

  public enforce(obj: AuthUserObject, act: AuthUserAction): boolean {
    return this.state.permissions.some(perm => perm.obj === obj && perm.act === act);
  }

  // 指定された action に対して一つでも権限があれば true を返す
  public enforceByActions(acts: AuthUserAction[]): boolean {
    return this.state.permissions.some(perm => acts.includes(perm.act));
  }

  public get isAdmin(): boolean {
    return this.state.isAdmin;
  }

  public checkHasGroupWrite(groupId: string): boolean {
    return this.state.permissions.some(
      perm => perm.act === 'group.write' && perm.obj === `${ENFORCE_OBJECT_GROUP_PREFIX}${groupId}`,
    );
  }

  public getTeamspaceIds(): string[] {
    return this.state.permissions
      .filter(
        perm =>
          perm.act === 'notebook.read' &&
          perm.obj.startsWith(ENFORCE_OBJECT_TEAMSPACE_PREFIX) &&
          perm.obj.split('/').pop()?.length === 24,
      )
      .map(perm => perm.obj.split('/').pop() as string);
  }

  public checkHasTeamspaceWrite(teamspaceId: string): boolean {
    return this.state.permissions.some(
      perm =>
        perm.act === 'teamspace.write' &&
        perm.obj === `${ENFORCE_OBJECT_TEAMSPACE_PREFIX}${teamspaceId}`,
    );
  }

  public checkHasProtectedNotebookWrite(teamspaceId: string): boolean {
    return this.state.permissions.some(
      perm =>
        perm.act === 'notebook.write' &&
        perm.obj === `${ENFORCE_OBJECT_TEAMSPACE_PREFIX}${teamspaceId}`,
    );
  }

  public checkHasProtectedNotebookPublish(teamspaceId: string): boolean {
    return this.state.permissions.some(
      perm =>
        perm.act === 'notebook.publish' &&
        perm.obj === `${ENFORCE_OBJECT_TEAMSPACE_PREFIX}${teamspaceId}`,
    );
  }

  public checkHasProtectedNotebookShare(teamspaceId: string): boolean {
    return this.state.permissions.some(
      perm =>
        perm.act === 'notebook.share' &&
        perm.obj === `${ENFORCE_OBJECT_TEAMSPACE_PREFIX}${teamspaceId}`,
    );
  }

  public checkHasConnectionWrite(
    connectionId: string,
    accessLevel: ConnectionAccessLevel,
  ): boolean {
    if (
      accessLevel === CONNECTION_ACCESS_LEVELS.PUBLIC ||
      accessLevel === CONNECTION_ACCESS_LEVELS.PROTECTED
    ) {
      return (
        this.state.permissions.some(perm => perm.act === 'workspace.write' && perm.obj === 'ws') ||
        this.state.permissions.some(
          perm =>
            perm.act === 'connection.write' &&
            perm.obj === `${ENFORCE_OBJECT_CONNECTION_PREFIX}${connectionId}`,
        )
      );
    } else if (accessLevel === CONNECTION_ACCESS_LEVELS.PRIVATE) {
      return this.state.permissions.some(
        perm =>
          perm.act === 'connection.write' &&
          perm.obj === `${ENFORCE_OBJECT_CONNECTION_PREFIX}${connectionId}`,
      );
    }
    return false;
  }

  public checkHasConnectionExecute(
    connectionId: string,
    accessLevel: ConnectionAccessLevel,
  ): boolean {
    if (accessLevel === CONNECTION_ACCESS_LEVELS.PUBLIC) {
      return this.state.permissions.some(
        perm => perm.act === 'connection.execute' && perm.obj === 'ws/conn/public',
      );
    } else if (
      accessLevel === CONNECTION_ACCESS_LEVELS.PROTECTED ||
      accessLevel === CONNECTION_ACCESS_LEVELS.PRIVATE
    ) {
      return this.state.permissions.some(
        perm =>
          perm.act === 'connection.execute' &&
          perm.obj === `${ENFORCE_OBJECT_CONNECTION_PREFIX}${connectionId}`,
      );
    }
    return false;
  }

  public checkHasConnectionPublish(
    connectionId: string,
    accessLevel: ConnectionAccessLevel,
  ): boolean {
    if (accessLevel === CONNECTION_ACCESS_LEVELS.PUBLIC) {
      return this.state.permissions.some(
        perm => perm.act === 'connection.publish' && perm.obj === 'ws/conn/public',
      );
    } else if (
      accessLevel === CONNECTION_ACCESS_LEVELS.PROTECTED ||
      accessLevel === CONNECTION_ACCESS_LEVELS.PRIVATE
    ) {
      return this.state.permissions.some(
        perm =>
          perm.act === 'connection.publish' &&
          perm.obj === `${ENFORCE_OBJECT_CONNECTION_PREFIX}${connectionId}`,
      );
    }
    return false;
  }

  public getResourceRoleCanLabel(
    role: ResourceRole | NotebookSharedRole | ReportSharedRole,
  ): string {
    return getResourceRoleCanLabel(role);
  }

  public getResourceRoleCanDescription(
    role: ResourceRole | NotebookSharedRole | ReportSharedRole,
  ): string {
    return RESOURCE_ROLE_CAN_DESCRIPTION[role] ?? '(Unknown role)';
  }

  public getTeamspaceRoleOptions(): {
    canLabel: string;
    canDescription: string;
    value: TeamspaceRole;
  }[] {
    return Object.values(RESOURCE_ROLES.TEAMSPACE)
      .filter(role => role !== 'teamspace.owner')
      .map(role => ({
        canLabel: this.getResourceRoleCanLabel(role),
        canDescription: this.getResourceRoleCanDescription(role),
        value: role,
      }));
  }

  public getConnectionRoleOptions(): {
    canLabel: string;
    canDescription: string;
    value: ConnectionRole;
  }[] {
    return Object.values(RESOURCE_ROLES.CONNECTION)
      .filter(role => role !== 'connection.owner')
      .map(role => ({
        canLabel: this.getResourceRoleCanLabel(role),
        canDescription: this.getResourceRoleCanDescription(role),
        value: role,
      }));
  }

  public getNotebookShareRoleOptions(): {
    canLabel: string;
    canDescription: string;
    value: NotebookSharedRole;
  }[] {
    return Object.values(NOTEBOOK_SHARED_ROLES).map(role => ({
      canLabel: this.getResourceRoleCanLabel(role),
      canDescription: this.getResourceRoleCanDescription(role),
      value: role,
    }));
  }
}
