import { type Ref, computed } from 'vue';
import { useQuery, useQueries, useQueryClient } from '@tanstack/vue-query';
import { matchSorter } from 'match-sorter';
import type {
  WorkspaceAccount as _WorkspaceAccount,
  WorkspaceAccountAccount,
} from '@account-shared-types';
import DataLoader from 'dataloader';
import { useCurrentWorkspaceId } from './useWorkspace';
import { accountClient } from '@cdm/clients/AccountClient';
import { getWorkspaceRoleDisplayName } from '@cdm/domains/account/shared/stores/PermissionStore';
import type { QueryOptions } from '@cdm/clients/QueryOptions';
import { DataLoaderCache } from '@cdm/utils/dataLoader/DataLoaderCache';
import { notNullFilter } from '@cdm/utils/notNullFilter';

export type WorkspaceAccount = _WorkspaceAccount & {
  account: WorkspaceAccountAccount & { display_name: string; sub_display_name: string };
};

type AccountKind = 'members' | 'guests' | 'all';
const DEFAULT_ACCOUNT_KIND: AccountKind = 'members';

export function useWorkspaceAccounts(
  accountKind: AccountKind = DEFAULT_ACCOUNT_KIND,
  options: QueryOptions = {},
) {
  const wsId = useCurrentWorkspaceId();
  const fetchKinds = accountKind === 'all' ? ['members', 'guests'] : [accountKind];

  const queries = useQueries({
    queries: fetchKinds.map(kind => ({
      queryKey: ['account', 'wsaccount', 'getAccounts', wsId, kind],
      queryFn: async () => {
        if (kind === 'members') {
          return await accountClient.wsaccount_getMembers({ data: {} }, { ws: wsId.value });
        } else if (kind === 'guests') {
          return await accountClient.wsaccount_getGuests({ data: {} }, { ws: wsId.value });
        } else {
          throw new Error('Invalid accountKind');
        }
      },
      ...options,
    })),
  });

  const isLoading = computed(() => {
    return queries.value.some(q => q.isLoading);
  });

  const refetch = () => {
    queries.value.forEach(q => q.refetch());
  };

  const error = computed(() => {
    return queries.value.find(q => !!q.error) as Error | undefined;
  });

  const workspaceAccounts = computed<WorkspaceAccount[]>(() => {
    return queries.value
      ?.flatMap(q => q.data?.accounts ?? [])
      .map(account => {
        return {
          ...account,
          account: {
            ...account.account,
            display_name: getAccountDisplayName(account.account),
            sub_display_name: getAccountSubDisplayName(account.account),
          },
        };
      });
  });

  const getWorkspaceAccount = (accountId: string) => {
    return workspaceAccounts.value.find(account => account.account?.id === accountId) || null;
  };

  function getFilteredWorkspaceAccounts(keyword: string) {
    return filterWorkspaceAccounts(workspaceAccounts.value, keyword);
  }

  const getWorkspaceAccountsForPermissions = () => {
    return workspaceAccounts.value
      .filter(account => {
        return !!account.account;
      })
      .map(account => ({
        accountId: account.account.id,
        wsAccountId: account.id,
        name: getAccountDisplayName(account.account),
        email: account.account.email,
        role: account.role,
        roleDisplay: getWorkspaceRoleDisplayName(account.role),
        groupIds: account.groups?.map(group => group.group_id) ?? [],
      }));
  };

  const getWsAccountIdByAccountId = (accountId: string) => {
    const wsAccount = getWorkspaceAccount(accountId);
    return wsAccount?.id ?? null;
  };

  return {
    isLoading,
    refetch,
    error,
    workspaceAccounts,
    getWorkspaceAccount,
    getFilteredWorkspaceAccounts,
    getWorkspaceAccountsForPermissions,
    getWsAccountIdByAccountId,
  };
}

export function useInvalidateWorkspaceAccounts() {
  const wsId = useCurrentWorkspaceId();
  const queryClient = useQueryClient();

  function invalidateWorkspaceAccounts() {
    ['members', 'guests'].forEach(kind => {
      queryClient.invalidateQueries({
        queryKey: ['account', 'wsaccount', 'getAccounts', wsId, kind],
      });
    });
    queryClient.invalidateQueries({
      queryKey: ['account', 'wsaccount', 'getAccountsById', wsId],
    });
  }

  function invalidateWorkspaceAccount(accountId: string) {
    ['members', 'guests'].forEach(kind => {
      queryClient.invalidateQueries({
        queryKey: ['account', 'wsaccount', 'getAccounts', wsId, kind],
      });
    });
    queryClient.invalidateQueries({
      queryKey: ['account', 'wsaccount', 'getAccountsById', wsId, accountId],
    });
  }

  return {
    invalidateWorkspaceAccounts,
    invalidateWorkspaceAccount,
  };
}

const namelessDisplayName = '(Nameless user)';

export function getAccountDisplayName(account: { view_name: string; full_name: string }) {
  if (account.view_name) {
    return account.view_name;
  }
  if (account.full_name) {
    return account.full_name;
  }
  return namelessDisplayName;
}

export function getAccountSubDisplayName(account: { view_name: string; full_name: string }) {
  if (account.view_name) {
    return account.full_name;
  }
  return '';
}

export function getImageAltName(account: { view_name: string; full_name: string }) {
  if (!account.full_name && !account.view_name) return '';
  return getAccountDisplayName(account).slice(0, 1);
}

export function filterWorkspaceAccounts<T extends _WorkspaceAccount>(
  workspaceAccounts: T[],
  keyword: string,
): T[] {
  // memo: 重かったら自作する
  return matchSorter(workspaceAccounts, keyword, {
    threshold: matchSorter.rankings.CONTAINS,
    keys: ['account.full_name', 'account.view_name', 'account.email'],
  });
}

// accountIdをkeyにしてWorkspaceAccountを取得する
// dataLoaderを使ってキューイングしてからまとめてapi callする
// accountId単位でキャッシュのコントロールは行っている
const dataLoaderCache = new DataLoaderCache<string, string, _WorkspaceAccount>();
export function useWorkspaceAccount(accountId: Ref<string>, options: QueryOptions = {}) {
  const wsId = useCurrentWorkspaceId();
  const loader = computed(() => {
    return dataLoaderCache.getOrCreate(wsId.value, wsId => {
      return new DataLoader(
        (accountIds: readonly string[]) => batchLoadWorkspaceAccounts(wsId, accountIds),
        { cache: false }, // キャッシュはvue-query側で行うのでfalse
      );
    });
  });

  const result = useQuery({
    queryKey: ['account', 'wsaccount', 'getAccountsById', wsId, accountId],
    queryFn: async () => {
      return await loader.value.load(accountId.value);
    },
    ...options,
  });

  const workspaceAccount = computed<WorkspaceAccount | null>(() => {
    const account = result.data.value;
    if (!account) return null;

    return {
      ...account,
      account: {
        ...account.account,
        display_name: getAccountDisplayName(account.account),
        sub_display_name: getAccountSubDisplayName(account.account),
      },
    };
  });

  return {
    ...result,
    workspaceAccount,
  };
}

export function useWorkspaceAccountsByIds(accountIds: Ref<string[]>, options: QueryOptions = {}) {
  const wsId = useCurrentWorkspaceId();
  const loader = computed(() => {
    return dataLoaderCache.getOrCreate(wsId.value, wsId => {
      return new DataLoader(
        (accountIds: readonly string[]) => batchLoadWorkspaceAccounts(wsId, accountIds),
        { cache: false }, // キャッシュはvue-query側で行うのでfalse
      );
    });
  });

  const sourceQueries = computed(() =>
    accountIds.value.map(accountId => {
      return {
        queryKey: ['account', 'wsaccount', 'getAccountsById', wsId, accountId],
        queryFn: async () => {
          return await loader.value.load(accountId);
        },
        ...options,
      };
    }),
  );

  const results = useQueries({
    queries: sourceQueries,
  });

  const workspaceAccounts = computed<WorkspaceAccount[]>(() => {
    return results.value
      .map(result => {
        const account = result.data;
        if (!account) return null;

        return {
          ...account,
          account: {
            ...account.account,
            display_name: getAccountDisplayName(account.account),
            sub_display_name: getAccountSubDisplayName(account.account),
          },
        };
      })
      .filter(notNullFilter);
  });

  const isLoading = computed(() => {
    return results.value.some(q => q.isLoading);
  });

  const error = computed(() => {
    return results.value.find(q => !!q.error) as Error | undefined;
  });

  return {
    workspaceAccounts,
    isLoading,
    error,
  };
}

async function batchLoadWorkspaceAccounts(
  workspaceId: string,
  accountIds: readonly string[],
): Promise<(_WorkspaceAccount | null)[]> {
  if (accountIds.length === 0) return [];
  const wsAccounts = await accountClient.wsaccount_getAccountsByIds(
    { accountIds: [...accountIds] },
    { ws: workspaceId },
  );

  const accounts = wsAccounts.accounts || [];

  // 元の配列の順番に並び替える
  const wsAccountsMap = new Map(accounts.map(account => [account.account.id, account]));
  return accountIds.map(accountId => wsAccountsMap.get(accountId) || null);
}
