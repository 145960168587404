import type { EngineType, EngineConfig } from '../types/engine';

export const ENGINE_TYPES = {
  BIGQUERY: 'BIGQUERY',
  REDSHIFT: 'REDSHIFT',
  UNKNOWN: 'UNKNOWN',
} as const;

export const ENGINE_CONFIGS: {
  [key in EngineType]: EngineConfig;
} = {
  [ENGINE_TYPES.BIGQUERY]: {
    dryRunnable: true,
    fieldStatsAvailable: true,
  },
  [ENGINE_TYPES.REDSHIFT]: {
    dryRunnable: true,
    fieldStatsAvailable: false,
  },
  [ENGINE_TYPES.UNKNOWN]: {
    dryRunnable: false,
    fieldStatsAvailable: false,
  },
};

export const BIGQUERY_RESOURCE_KINDS = {
  CONNECTION: 'CONNECTION',
  JOB: 'JOB',
  PROJECT: 'PROJECT',
  DATASET: 'DATASET',
  TABLE: 'TABLE',
  TABLE_ROWS: 'TABLE_ROWS',
} as const;

export const REDSHIFT_RESOURCE_KINDS = {
  CONNECTION: 'CONNECTION',
  JOB: 'JOB',
} as const;
