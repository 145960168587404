import type { NodeName } from '@cdm/@shared-server-notebook/types/notebook';
import type { Editor } from '@tiptap/core';
import { NodeSelection } from '@tiptap/pm/state';

// MEMO: editor は tiptap の editor の場合もあれば、 notebook用の拡張済の editor の場合もあるので generic にする
export type KeyboardShortcutCommand<E extends Editor = Editor> = (props: { editor: E }) => boolean;
export type KeyboardShortcutBindings<E extends Editor = Editor> = {
  [keyCode: string]: KeyboardShortcutCommand<E>;
};

// keyCode の衝突を考慮しながら bindings に command を追加
export const mergeKeyboardShortcutBinding = <E extends Editor = Editor>(
  bindings: KeyboardShortcutBindings<E>,
  keyCode: string,
  cmd: KeyboardShortcutCommand<E>,
  appendBefore: boolean = false,
): KeyboardShortcutBindings<E> => {
  if (!bindings[keyCode]) {
    bindings[keyCode] = cmd;
  } else {
    if (!appendBefore) {
      bindings[keyCode] = chainKeyboardShortcutsCommand(bindings[keyCode], cmd);
    } else {
      bindings[keyCode] = chainKeyboardShortcutsCommand(cmd, bindings[keyCode]);
    }
  }
  return bindings;
};

// 複数の bindings をマージする
export const mergeKeyboardShortcutBindings = <E extends Editor = Editor>(
  ...bindingsArray: KeyboardShortcutBindings<E>[]
): KeyboardShortcutBindings<E> => {
  const bindings: KeyboardShortcutBindings<E> = {};
  bindingsArray.forEach(b => {
    Object.keys(b).forEach(keyCode => {
      mergeKeyboardShortcutBinding(bindings, keyCode, b[keyCode]);
    });
  });
  return bindings;
};

// bindings に一括で filter(条件付きで cmd を実行する cmd) を追加
export const applyFilterToKeyboardShortcutBindings = <
  FilterArgs extends Array<any>,
  E extends Editor = Editor,
>(
  bindings: KeyboardShortcutBindings<E>,
  filter: (cmd: KeyboardShortcutCommand<E>, ...args: FilterArgs) => KeyboardShortcutCommand<E>,
  args: FilterArgs,
): KeyboardShortcutBindings<E> => {
  Object.keys(bindings).forEach(keyCode => {
    bindings[keyCode] = filter(bindings[keyCode], ...args);
  });
  return bindings;
};

// KeyboardShortcutCommand を順番に実行して true を返した時点で処理をやめる
export const chainKeyboardShortcutsCommand =
  <E extends Editor = Editor>(...cmds: KeyboardShortcutCommand<E>[]): KeyboardShortcutCommand<E> =>
  props => {
    let result: boolean;
    for (const cmd of cmds) {
      result = cmd(props);
      if (result) return true;
    }
    return false;
  };

export const execIfAnchorParentMatch =
  <E extends Editor = Editor, N = NodeName>(
    cmd: KeyboardShortcutCommand<E>,
    nodeName: N,
  ): KeyboardShortcutCommand<E> =>
  (props: { editor: E }) => {
    const { $anchor, $from, $to } = props.editor.state.selection;
    if ($anchor.parent.type.name !== nodeName || !$from.sameParent($to)) return false;
    return cmd(props);
  };

export const execIfAnchorGrandParentMatch =
  <E extends Editor = Editor>(
    cmd: KeyboardShortcutCommand<E>,
    nodeName: NodeName,
  ): KeyboardShortcutCommand<E> =>
  (props: { editor: E }) => {
    const { $anchor, $from, $to } = props.editor.state.selection;
    const grandParent = $anchor.node($anchor.depth - 1);
    if (grandParent?.type.name !== nodeName || !$from.sameParent($to)) return false;
    return cmd(props);
  };

export const execIfNodeSelected =
  <E extends Editor = Editor>(
    cmd: KeyboardShortcutCommand<E>,
    nodeName: NodeName,
  ): KeyboardShortcutCommand<E> =>
  (props: { editor: E }) => {
    const { selection } = props.editor.state;
    if (!(selection instanceof NodeSelection && selection.node.type.name === nodeName))
      return false;
    return cmd(props);
  };
