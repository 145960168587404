import type {
  KeyboardShortcutPreferences,
  ShortcutPreference,
} from '@cdm/@generated-account-client/shared-types';
import { getShortcutKeyFormatter, normalizeKey } from './formatter';
import { SHORTCUT_DEFS, type ShortcutContext, type ShortcutGroup, type ShortcutKey } from './defs';
import type { KeyDef } from './types';

export const eventToShortcut = (ev: KeyboardEvent): ShortcutPreference => {
  const { key, code, keyCode, ctrlKey, altKey, shiftKey, metaKey } = ev;
  const modifiers = [
    ctrlKey ? 'Ctrl' : '',
    altKey ? 'Alt' : '',
    shiftKey ? 'Shift' : '',
    metaKey ? 'Meta' : '',
  ].filter(v => v) as ShortcutPreference['modifiers'];
  return { key, code, keyCode, modifiers };
};

export const findConflicts = (
  preferences: KeyboardShortcutPreferences,
  context: ShortcutContext,
  key: ShortcutKey<ShortcutContext>,
  shortcut: ShortcutPreference,
) => {
  const conflicts: { context: ShortcutContext; keyDef: KeyDef<ShortcutGroup<ShortcutContext>> }[] =
    [];

  Object.keys(SHORTCUT_DEFS).forEach(c => {
    // 検査対象はGlobal contextと同一context内のショートカット
    if (c === 'Global' || c === context) {
      const { KEYS } = SHORTCUT_DEFS[c as ShortcutContext];
      Object.entries(KEYS).forEach(([k, def]) => {
        if (c === context && k === key) return;
        const currentShortcut =
          preferences[c as ShortcutContext]?.[k as ShortcutKey<ShortcutContext>];
        if (isShortcutExists(currentShortcut || [def.preference], shortcut)) {
          conflicts.push({
            context: c as ShortcutContext,
            keyDef: def as KeyDef<ShortcutGroup<ShortcutContext>>,
          });
        }
      });
    }
  });
  return conflicts;
};

export const isShortcutExists = (
  preferences: ShortcutPreference[],
  preference: ShortcutPreference,
) => {
  const formatter = getShortcutKeyFormatter();
  return preferences.some(p => {
    const p1 = { ...p, modifiers: (p.modifiers || []).slice().sort() };
    const p2 = { ...preference, modifiers: (preference.modifiers || []).slice().sort() };
    return formatter.getKeyCodeDisplay(p1) === formatter.getKeyCodeDisplay(p2);
  });
};

export const isNormalizedShortcutExists = (
  preferences: ShortcutPreference[],
  preference: ShortcutPreference,
) => {
  return isShortcutExists(
    preferences.map(p => normalizeKey(p)),
    normalizeKey(preference),
  );
};
