import type { App } from 'vue';
import type { IdentifyProps, IdentifyPropsWithCompany, TrackerModule } from '../types';

export class IntercomTracker implements TrackerModule {
  isOpened: boolean = false;
  isInitialized: boolean = false;

  get intercom(): any {
    return (window as any).Intercom;
  }
  init(app: App<any>): void {
    // do nothing
  }
  view(): void {
    // do nothing
  }
  track(event: string, properties: any) {
    // do nothing
  }
  identifyWithCompany(properties: IdentifyPropsWithCompany): void {
    // do nothing
  }

  identify({ id, name, email, intercomHash }: IdentifyProps): void {
    if (!intercomHash) return;
    const icom = this.intercom;
    if (!icom) {
      return;
    }
    if (this.isInitialized) return;
    this.isInitialized = true;
    icom('onShow', () => {
      this.isOpened = true;
    });
    icom('onHide', () => {
      this.isOpened = false;
    });
    this.processAfterInitialized();
  }

  openChat(): void {
    this.queueFuncAfterInitialized(icom => {
      if (this.isOpened) {
        icom('hide');
        return;
      }
      icom('show');
    });
  }

  setUnreadCountChangedListener(callback: (count: number) => void): void {
    this.queueFuncAfterInitialized(icom => {
      icom('onUnreadCountChange', callback);
    });
  }

  funcQueueAfterInitialized: ((icom: any) => void)[] = [];
  private queueFuncAfterInitialized(func: (icom: any) => void) {
    const icom = this.intercom;
    if (this.isInitialized) {
      func(icom);
    } else {
      this.funcQueueAfterInitialized.push(func);
    }
  }

  private processAfterInitialized() {
    const icom = this.intercom;
    this.funcQueueAfterInitialized.forEach(func => {
      func(icom);
    });
    this.funcQueueAfterInitialized = [];
  }
}
