export type Fetcher = (path: string, requestData: Record<string, unknown>, query: Record<string, string>, options: Record<string, any>) => Promise<any>;

export class BaseClient {
  fetcher: Fetcher;

  constructor(fetcher: Fetcher) {
    this.fetcher = fetcher;
  }

  async fetch(path: string, requestData: Record<string, unknown> = {}, query: Record<string, string> = {}, options: Record<string, any> = {}) {
    return this.fetcher(path, requestData, query, options);
  }
}
