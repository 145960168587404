import type { ShortcutPreference } from '@cdm/@generated-account-client/shared-types';
import { base } from 'w3c-keyname';
import { isMac, isWindows } from '../browserEnv';

const MAP_MODIFIER_KEYS = {
  Meta: 'Meta',
  Alt: 'Alt',
  Control: 'Control',
  Shift: 'Shift',
} as const;

const MAP_SYMBOL_KEYS = {
  Plus: '+',
  Minus: '-',
  Equal: '=',
} as const;

const MAP_ARROW_KEYS = {
  ArrowUp: '↑',
  ArrowDown: '↓',
  ArrowRight: '→',
  ArrowLeft: '←',
} as const;

type MapModifierKeys = keyof typeof MAP_MODIFIER_KEYS;
type MapSymbolKeys = keyof typeof MAP_SYMBOL_KEYS;
type MapArrowKeys = keyof typeof MAP_ARROW_KEYS;

type KeyCodeToDisplayMap = Record<MapModifierKeys | MapSymbolKeys | MapArrowKeys, string>;

export const formatToGithubHotkeyStyleKeyCode = (
  key: string,
  modifiers: ShortcutPreference['modifiers'] = [],
) => {
  if (/^\w$/.test(key) && modifiers?.length === 1 && modifiers[0] === 'Shift') {
    key = key.toUpperCase();
    modifiers = [];
  }
  // https://github.com/github/hotkey?tab=readme-ov-file#hotkey-string-format
  // Modifier key combos are separated with a + and are prepended to a key in a consistent order as follows: "Control+Alt+Meta+Shift+KEY".
  const sortedModifiers = (modifiers || []).slice().sort((a, b) => {
    const order = ['Control', 'Alt', 'Meta', 'Shift'];
    return order.indexOf(a) - order.indexOf(b);
  });
  const keys = [...(sortedModifiers || []), key].filter(v => !!v);
  return keys
    .map(k => {
      if (k === 'Ctrl') return 'Control';
      return k;
    })
    .join('+');
};

export const formatToProsemirrorStyleKeyCode = (
  key: string,
  modifiers: ShortcutPreference['modifiers'] = [],
) => {
  const keys = [...(modifiers || []), key].filter(v => !!v);
  return keys.join('-');
};

export const normalizeKey = (preference: ShortcutPreference): ShortcutPreference => {
  const normalizer = (replacer: (s: string) => string) => {
    return (key: string) => replacer(key);
  };
  const getReplacer = () => {
    if (isMac) {
      return (s: string) => (s === 'Mod' ? 'Meta' : s);
    }
    return (s: string) => (s === 'Mod' ? 'Control' : s);
  };
  const normalize = normalizer(getReplacer());
  return {
    key: preference.key,
    code: preference.code,
    keyCode: preference.keyCode,
    modifiers: (preference.modifiers || []).map((mod: string) =>
      normalize(mod),
    ) as ShortcutPreference['modifiers'],
  };
};

export abstract class ShortcutKeyFormatter {
  abstract keyCodeToText: KeyCodeToDisplayMap;
  abstract keyCodeToTextWithIcon: KeyCodeToDisplayMap;

  private getProsemirrorStyleKey(key: string, keyCode?: number) {
    const keyname = keyCode ? base[keyCode] : '';
    return keyname || key;
  }

  private _getKeyCodes(preference: ShortcutPreference, map?: KeyCodeToDisplayMap) {
    const normalized = normalizeKey(preference);
    const key = this.getProsemirrorStyleKey(normalized.key, normalized.keyCode);
    return [...(normalized.modifiers || []), key]
      .filter(v => !!v)
      .map(key => {
        if (!map) return key;
        return key in map ? map[key as keyof KeyCodeToDisplayMap] : key;
      });
  }

  private _getKeyCodeDisplay(
    preference: ShortcutPreference,
    delimiter: '+' | '-' | ' ' = '+',
    map?: KeyCodeToDisplayMap,
  ) {
    const _delimiter = delimiter === ' ' ? ' ' : ` ${delimiter} `;
    return this._getKeyCodes(preference, map).join(_delimiter);
  }

  getKeyCode(preference: ShortcutPreference, delimiter: '+' | '-' | ' ' = '+') {
    return this._getKeyCodeDisplay(preference, delimiter);
  }

  getKeyCodeDisplay(preference: ShortcutPreference, delimiter: '+' | '-' | ' ' = '+') {
    return this._getKeyCodeDisplay(preference, delimiter, this.keyCodeToText);
  }

  getKeyCodeDisplayWithIcon(preference: ShortcutPreference, delimiter: '+' | '-' | ' ' = '+') {
    return this._getKeyCodeDisplay(preference, delimiter, this.keyCodeToTextWithIcon);
  }

  getProsemirrorKeyCode(preference: ShortcutPreference) {
    const normalized = normalizeKey(preference);
    const basename = normalized.keyCode ? base[normalized.keyCode] : '';
    const key = basename || normalized.key;
    return formatToProsemirrorStyleKeyCode(key, normalized.modifiers);
  }

  getGithubHotkey(preference: ShortcutPreference) {
    const normalized = normalizeKey(preference);
    return formatToGithubHotkeyStyleKeyCode(normalized.key, normalized.modifiers);
  }

  getSearchKeywords(preference: ShortcutPreference): string[] {
    const keywords: string[] = [];

    const keyCodesText = this._getKeyCodes(preference, this.keyCodeToText);
    const keyCodesTextIcon = this._getKeyCodes(preference, this.keyCodeToTextWithIcon);
    keyCodesText.forEach(code => {
      if (code === 'Control') {
        keywords.push(...['ctrl', 'control']);
      } else if (code === 'Shift') {
        keywords.push(...['shift']);
      } else if (code === 'Alt') {
        keywords.push(...['alt']);
      } else if (code === 'Option') {
        keywords.push(...['option', 'opt']);
      } else if (code === 'Command') {
        keywords.push(...['command', 'cmd']);
      } else if (code === 'Windows') {
        keywords.push(...['windows', 'win']);
      } else {
        keywords.push(code);
      }
    });
    return Array.from(new Set([...keyCodesTextIcon, ...keywords]));
  }
  toSearchWord(preference: ShortcutPreference): string {
    const keyCodes = this._getKeyCodes(preference, this.keyCodeToText);
    return keyCodes.map(c => c.toLowerCase()).join(' ');
  }
}
class MacShortcutKeyFormatter extends ShortcutKeyFormatter {
  keyCodeToText = {
    ...MAP_MODIFIER_KEYS,
    ...MAP_SYMBOL_KEYS,
    ...MAP_ARROW_KEYS,
    Meta: 'Command',
    Alt: 'Option',
  };
  keyCodeToTextWithIcon = {
    ...MAP_MODIFIER_KEYS,
    ...MAP_SYMBOL_KEYS,
    ...MAP_ARROW_KEYS,
    Meta: '⌘',
    Alt: '⌥',
  };
}
class WindowsShortcutKeyFormatter extends ShortcutKeyFormatter {
  keyCodeToText = {
    ...MAP_MODIFIER_KEYS,
    ...MAP_SYMBOL_KEYS,
    ...MAP_ARROW_KEYS,
    Meta: 'Windows',
  };
  keyCodeToTextWithIcon = {
    ...MAP_MODIFIER_KEYS,
    ...MAP_SYMBOL_KEYS,
    ...MAP_ARROW_KEYS,
    Meta: '⊞',
  };
}
class GeneralShortcutKeyFormatter extends ShortcutKeyFormatter {
  keyCodeToText = {
    ...MAP_MODIFIER_KEYS,
    ...MAP_SYMBOL_KEYS,
    ...MAP_ARROW_KEYS,
  };
  keyCodeToTextWithIcon = {
    ...MAP_MODIFIER_KEYS,
    ...MAP_SYMBOL_KEYS,
    ...MAP_ARROW_KEYS,
  };
}

export const getShortcutKeyFormatter = (): ShortcutKeyFormatter => {
  if (isMac) {
    return new MacShortcutKeyFormatter();
  } else if (isWindows) {
    return new WindowsShortcutKeyFormatter();
  } else {
    return new GeneralShortcutKeyFormatter();
  }
};
