import { datadogRum } from '@datadog/browser-rum';
import { GlobalConfig } from '@cdm/configs';

if (GlobalConfig.DATADOG_APPLICATION_ID && GlobalConfig.DATADOG_CLIENT_TOKEN) {
  datadogRum.init({
    applicationId: GlobalConfig.DATADOG_APPLICATION_ID,
    clientToken: GlobalConfig.DATADOG_CLIENT_TOKEN,
    site: GlobalConfig.DATADOG_SITE,
    service: 'codatum',
    env: GlobalConfig.ENVIRONMENT,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: GlobalConfig.DATADOG_SESSION_SAMPLE_RATE,
    sessionReplaySampleRate: GlobalConfig.DATADOG_SESSION_REPLAY_SAMPLE_RATE,
    trackUserInteractions: false,
    defaultPrivacyLevel: 'mask-user-input',
  });
}
