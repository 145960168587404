<template>
  <template v-if="$props.content || $slots['content']">
    <ArcoTooltip
      v-bind="{
        ...$props,
        ...$attrs,
        'mouse-enter-delay': $attrs['mouse-enter-delay'] || 200,
      }"
      v-on="emits"
    >
      <template v-for="(_, name) in $slots" #[name]="slotProps">
        <slot v-if="slotProps" :name="name" v-bind="slotProps" />
        <slot v-else :name="name" />
      </template>
    </ArcoTooltip>
  </template>
  <template v-else>
    <slot />
  </template>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Tooltip as ArcoTooltip } from '@arco-design/web-vue';

/**
 * acro の tooltip の以下の挙動を修正するための wrapper
 * - content が空文字の時にゴミが表示される
 * - mouse-enter-delay のデフォルトの上書き
 */
export default defineComponent({
  components: {
    ArcoTooltip,
  },
  extends: ArcoTooltip,
  inheritAttrs: false,
  setup(props, ctx) {
    const emits: { [key: string]: (...args: any[]) => void } = {};
    Object.keys(ArcoTooltip.emits ?? {}).forEach(key => {
      emits[key] = (...args: any[]) => {
        ctx.emit(key, ...args);
      };
    });
    return { emits };
  },
});
</script>
