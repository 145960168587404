import bqFunctionsStore from './bqFunctions/bqFunctionsStore';

export const BQ_DATATYPES =
  'INT64|NUMERIC|BIGNUMERIC|FLOAT64|STRING|BOOL|BYTES|DATE|DATETIME|TIME|TIMESTAMP|ARRAY|STRUCT|GEOGRAPHY|JSON|INTERVAL'.split(
    '|',
  );

// 予約語
// https://cloud.google.com/bigquery/docs/reference/standard-sql/lexical#reserved_keywords
export const BQ_RESERVED_KEYWORDS = [
  'ALL',
  'AND',
  'ANY',
  'ARRAY',
  'AS',
  'ASC',
  'ASSERT_ROWS_MODIFIED',
  'AT',
  'BETWEEN',
  'BY',
  'CASE',
  'CAST',
  'COLLATE',
  'CONTAINS',
  'CREATE',
  'CROSS',
  'CUBE',
  'CURRENT',
  'DEFAULT',
  'DEFINE',
  'DESC',
  'DISTINCT',
  'ELSE',
  'END',
  'ENUM',
  'ESCAPE',
  'EXCEPT',
  'EXCLUDE',
  'EXISTS',
  'EXTRACT',
  'FALSE',
  'FETCH',
  'FOLLOWING',
  'FOR',
  'FROM',
  'FULL',
  'GROUP',
  'GROUPING',
  'GROUPS',
  'HASH',
  'HAVING',
  'IF',
  'IGNORE',
  'IN',
  'INNER',
  'INTERSECT',
  'INTERVAL',
  'INTO',
  'IS',
  'JOIN',
  'LATERAL',
  'LEFT',
  'LIKE',
  'LIMIT',
  'LOOKUP',
  'MERGE',
  'NATURAL',
  'NEW',
  'NO',
  'NOT',
  'NULL',
  'NULLS',
  'OF',
  'ON',
  'OR',
  'ORDER',
  'OUTER',
  'OVER',
  'PARTITION',
  'PRECEDING',
  'PROTO',
  'QUALIFY',
  'RANGE',
  'RECURSIVE',
  'RESPECT',
  'RIGHT',
  'ROLLUP',
  'ROWS',
  'SELECT',
  'SET',
  'SOME',
  'STRUCT',
  'TABLESAMPLE',
  'THEN',
  'TO',
  'TREAT',
  'TRUE',
  'UNBOUNDED',
  'UNION',
  'UNNEST',
  'USING',
  'WHEN',
  'WHERE',
  'WINDOW',
  'WITH',
  'WITHIN',
];

// 入力補完用に GROUP BY みたいな組み合わせにしたもの
export const BQ_RESERVED_KEYWORDS_FOR_COMPLETION = [
  // 組み合わせで suggest する用
  'GROUP BY',
  'ORDER BY',
  'LEFT JOIN',
  'RIGHT JOIN',
  'INNER JOIN',
  'FULL JOIN',
  'CROSS JOIN',
  'NATURAL JOIN',
  'UNION ALL',
  'UNION DISTINCT',
  'SELECT', // 組み合わせではないが表示順を上げるため
  'SELECT DISTINCT',
  'SELECT AS',
  'LIMIT', // 組み合わせではないが表示順を上げるため
  'LIMIT BY',
  'PARTITION BY',
  'WINDOW OVER',
  'EXCEPT ALL',
  'EXCEPT DISTINCT',
  'INTERSECT ALL',
  'INTERSECT DISTINCT',
  'WITHIN GROUP',

  // 以下が元の予約後
  'ALL',
  'AND',
  'ANY',
  'ARRAY',
  'AS',
  'ASC',
  'ASSERT_ROWS_MODIFIED',
  'AT',
  'BETWEEN',
  // 'BY', // 上記組み合わせで代替
  'CASE',
  'CAST',
  'COLLATE',
  'CONTAINS',
  'CREATE',
  'CROSS',
  'CUBE',
  'CURRENT',
  'DEFAULT',
  'DEFINE',
  'DESC',
  'DISTINCT',
  'ELSE',
  'END',
  'ENUM',
  'ESCAPE',
  'EXCEPT',
  'EXCLUDE',
  'EXISTS',
  'EXTRACT',
  'FALSE',
  'FETCH',
  'FOLLOWING',
  'FOR',
  'FROM',
  'FULL',
  'GROUP',
  'GROUPING',
  'GROUPS',
  'HASH',
  'HAVING',
  'IF',
  'IGNORE',
  'IN',
  'INNER',
  'INTERSECT',
  'INTERVAL',
  'INTO',
  'IS',
  'JOIN',
  'LATERAL',
  'LEFT',
  'LIKE',
  'LOOKUP',
  'MERGE',
  'NATURAL',
  'NEW',
  'NO',
  'NOT',
  'NULL',
  'NULLS',
  'OF',
  // 'ON', // 上記組み合わせで代替
  'OR',
  'ORDER',
  'OUTER',
  'OVER',
  'PARTITION',
  'PRECEDING',
  'PROTO',
  'QUALIFY',
  'RANGE',
  'RECURSIVE',
  'RESPECT',
  'RIGHT',
  'ROLLUP',
  'ROWS',
  'SET',
  'SOME',
  'STRUCT',
  'TABLESAMPLE',
  'THEN',
  'TO',
  'TREAT',
  'TRUE',
  'UNBOUNDED',
  'UNION',
  'UNNEST',
  'USING',
  'WHEN',
  'WHERE',
  'WINDOW',
  'WITH',
  // 'WITHIN', // 単独で使われる可能性が低い
];

export const isValidBqFunctionName = bqFunctionsStore.isValidFunctionName;
export const getBqFunctionList = bqFunctionsStore.getBqFunctionList;
export const getBqFunctionReferenceHtml = (name: string | null | undefined) =>
  bqFunctionsStore.getBqFunctionReferenceHtml(name, { className: 'Notebook_bqFunctionReference' });
export const getBqFunctionCategories = bqFunctionsStore.getBqFunctionCategories;
export const getBqFunctionNamesByCategory = bqFunctionsStore.getBqFunctionNamesByCategory;
