import type DataLoader from 'dataloader';

export class DataLoaderCache<CacheKey, LoaderKey, LoaderValue> {
  private cache: Map<CacheKey, DataLoader<LoaderKey, LoaderValue | null>> = new Map();
  getOrCreate(
    key: CacheKey,
    loaderFn: (key: CacheKey) => DataLoader<LoaderKey, LoaderValue | null>,
  ) {
    if (!this.cache.has(key)) {
      this.cache.set(key, loaderFn(key));
    }
    return this.cache.get(key)!;
  }
}
