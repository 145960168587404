const isiOS = () => {
  return ['iPad', 'iPhone', 'iPod'].includes(navigator.platform);
};
const isMacOS = () => {
  if (typeof navigator === 'undefined') return false;
  // https://developer.mozilla.org/en-US/docs/Web/API/Navigator/platform#examples
  return navigator.platform.indexOf('Mac') === 0;
};

const isAppleDevice = () => {
  return isiOS() || isMacOS();
};

const _isWindows = () => {
  // https://developer.mozilla.org/en-US/docs/Web/API/Navigator/platform#usage_notes
  return navigator.platform === 'Win32' || navigator.userAgent.includes('Windows');
};

/**
 * キーボードのショートカット等で使うMac or not の判定
 */
export const isMac: boolean = (() => {
  return isAppleDevice();
})();

export const isWindows: boolean = (() => {
  return _isWindows();
})();
