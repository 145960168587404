// lowlight/lib/core.js が jest でうまく使えなかったので、
// 必要な部分だけ元ソースから拝借して .ts に変換（＋一部機能追加）
// https://github.com/wooorm/lowlight/blob/2fa686282eb4aa442766ff5efad282194cd21027/lib/core.js
import high from 'highlight.js/lib/core';
import type { HighlightOptions } from 'highlight.js';

const defaultPrefix = 'hljs-';

function highlight(language: string, value: string, options: { [key: string]: any } = {}) {
  let prefix = options.prefix;

  if (typeof language !== 'string') {
    throw new Error('Expected `string` for name, got `' + language + '`');
  }

  if (!high.getLanguage(language)) {
    throw new Error('Unknown language: `' + language + '` is not registered');
  }

  if (typeof value !== 'string') {
    throw new Error('Expected `string` for value, got `' + value + '`');
  }

  if (prefix === null || prefix === undefined) {
    prefix = defaultPrefix;
  }

  high.configure({ __emitter: HastEmitter, classPrefix: prefix });

  const result = high.highlight(value, {
    language,
    ignoreIllegals: true,
  });

  high.configure({});

  // `highlight.js` seems to use this (currently) for broken grammars, so let’s
  // keep it in there just to be sure.
  /* c8 ignore next 3 */
  if (result.errorRaised) {
    throw result.errorRaised;
  }

  // @ts-expect-error FIXME
  result._emitter.root.data.language = result.language;
  // @ts-expect-error FIXME
  result._emitter.root.data.relevance = result.relevance;

  // @ts-expect-error FIXME
  return result._emitter.root;
}

function highlightAuto(value: string, options: { [key: string]: any } = {}) {
  const subset = options.subset || high.listLanguages();
  let prefix = options.prefix;
  let index = -1;
  let result = {
    type: 'root',
    data: { language: null, relevance: 0 },
    children: [],
  };

  if (prefix === null || prefix === undefined) {
    prefix = defaultPrefix;
  }

  if (typeof value !== 'string') {
    throw new Error('Expected `string` for value, got `' + value + '`');
  }

  while (++index < subset.length) {
    const name = subset[index];

    if (!high.getLanguage(name)) continue;

    const current = highlight(name, value, options);

    if (current.data.relevance > result.data.relevance) result = current;
  }

  return result;
}

function registerLanguage(language: string, syntax: any): void {
  high.registerLanguage(language, syntax);
}

function listLanguages(): string[] {
  return high.listLanguages();
}

// これは独自拡張
function listLanguagesWithDisplayName(): { key: string; label: string }[] {
  return listLanguages().map(lang => {
    return {
      key: lang,
      label: high.getLanguage(lang)?.name || lang,
    };
  });
}

class HastEmitter {
  options: any;
  root: any;
  stack: any;

  constructor(options: HighlightOptions) {
    this.options = options;
    /** @type {Root} */
    this.root = {
      type: 'root',
      data: { language: null, relevance: 0 },
      children: [],
    };
    /** @type {[Root, ...Array<Span>]} */
    this.stack = [this.root];
  }

  addText(value: string): void {
    if (value === '') return;

    const current = this.stack[this.stack.length - 1];
    const tail = current.children[current.children.length - 1];

    if (tail && tail.type === 'text') {
      tail.value += value;
    } else {
      current.children.push({ type: 'text', value });
    }
  }

  addKeyword(value: string, name: string) {
    this.openNode(name);
    this.addText(value);
    this.closeNode();
  }

  addSublanguage(other: HastEmitter, name: string): void {
    const current = this.stack[this.stack.length - 1];
    const results = other.root.children;

    if (name) {
      current.children.push({
        type: 'element',
        tagName: 'span',
        properties: { className: [name] },
        children: results,
      });
    } else {
      current.children.push(...results);
    }
  }

  openNode(name: string): void {
    // First “class” gets the prefix. Rest gets a repeated underscore suffix.
    // See: <https://github.com/highlightjs/highlight.js/commit/51806aa>
    // See: <https://github.com/wooorm/lowlight/issues/43>
    const className = name
      .split('.')
      .map((d, i) => (i ? d + '_'.repeat(i) : this.options.classPrefix + d));
    const current = this.stack[this.stack.length - 1];
    /** @type {Span} */
    const child = {
      type: 'element',
      tagName: 'span',
      properties: { className },
      children: [],
    };

    current.children.push(child);
    this.stack.push(child);
  }

  closeNode() {
    this.stack.pop();
  }

  closeAllNodes() {
    // do nothing
  }

  finalize() {
    // do nothing
  }

  toHTML() {
    return '';
  }
}

export const lowlight = {
  highlight,
  highlightAuto,
  registerLanguage,
  listLanguages,
  listLanguagesWithDisplayName,
};
