import type { ShortcutPreference } from '@cdm/@generated-account-client/shared-types';
import {
  type ShortcutContext,
  GLOBAL_SHORTCUT_KEYS,
  type GlobalShortcutKey,
  NOTEBOOK_SHORTCUT_KEYS,
  type NotebookShortcutKey,
  SHORTCUT_DEFS,
  type GroupsDefs,
  type KeysDefs,
  type ShortcutKey,
  type ShortcutGroup,
} from './defs';
import type { GroupDefs, KeyDef } from './types';
import { ShortcutKeyFormatter, getShortcutKeyFormatter } from './formatter';
import { useKeyboardShortcutSettings } from '@cdm/domains/account/shared/hooks/useKeyboardShortcutSettings';

type ShortcutKeyData<C extends ShortcutContext> = {
  group: string;
  help?: string;
  commands: { key: ShortcutKey<C>; name: string; help?: string | undefined }[];
};
type ShortcutListData<C extends ShortcutContext> = ShortcutKeyData<C>[];

export const SHORTCUT_KEYS = {
  Global: GLOBAL_SHORTCUT_KEYS,
  Notebook: NOTEBOOK_SHORTCUT_KEYS,
} as const;
type ShortcutKeys<C extends ShortcutContext> = typeof SHORTCUT_KEYS[C];

export class ShortcutKeyProvider<C extends ShortcutContext> {
  private readonly formatter: ShortcutKeyFormatter;
  private readonly context: C;
  private readonly groupsDefs: GroupsDefs<C>;
  private readonly keysDefs: KeysDefs<C>;
  public readonly KEYS: ShortcutKeys<C>;

  constructor(formatter: ShortcutKeyFormatter, context: C) {
    this.formatter = formatter;
    this.context = context;
    this.groupsDefs = SHORTCUT_DEFS[context].GROUPS;
    this.keysDefs = SHORTCUT_DEFS[context].KEYS;
    this.KEYS = SHORTCUT_KEYS[context];
  }
  public generateShortcutListData() {
    const data: ShortcutListData<C> = [];

    const groupToCommands = new Map<ShortcutGroup<C>, ShortcutKeyData<C>>();

    Object.entries(this.groupsDefs).forEach(([group, def]) => {
      groupToCommands.set(group as ShortcutGroup<C>, {
        group: def.label,
        help: def.help,
        commands: [],
      });
    });

    Object.entries(this.keysDefs).forEach(([key, _def]) => {
      const group = groupToCommands.get(_def.group as ShortcutGroup<C>);
      group?.commands?.push({
        key: key as ShortcutKey<C>,
        name: _def.label,
        help: _def.help,
      });
    });
    data.push(...groupToCommands.values());
    return data;
  }
  getShortcutListData() {
    return this.generateShortcutListData();
  }
  getKeyDef(name: ShortcutKey<C>) {
    return this.keysDefs[name] as KeyDef<GroupDefs<C>>;
  }
  getPreferences(name: ShortcutKey<C>): ShortcutPreference[] {
    const { getShortcut } = useKeyboardShortcutSettings();
    const current = getShortcut(this.context, name);
    if (current) return current;
    return [this.getKeyDef(name).preference];
  }
  getOriginalKeyCodes(name: ShortcutKey<C>): Array<string> {
    const keyDef = this.getKeyDef(name);
    const prefs = [keyDef.preference];
    Array<string>;
    return prefs.map(p => this.formatter.getProsemirrorKeyCode(p));
  }
  getKeyCodes(name: ShortcutKey<C>): Array<string> {
    const prefs = this.getPreferences(name);
    return prefs.map(p => this.formatter.getProsemirrorKeyCode(p));
  }
  getKeyCodesDisplay(name: ShortcutKey<C>): Array<string> {
    const prefs = this.getPreferences(name);
    return prefs.map(p => this.formatter.getKeyCodeDisplay(p));
  }
  getKeyCodesDisplayWithIcon(name: ShortcutKey<C>): Array<string> {
    const prefs = this.getPreferences(name);
    return prefs.map(p => this.formatter.getKeyCodeDisplayWithIcon(p));
  }
  getKeyCodeDisplay(name: ShortcutKey<C>): string {
    return this.getKeyCodesDisplay(name).join(', ');
  }
  getKeyCodeDisplayWithIcon(name: ShortcutKey<C>): string {
    return this.getKeyCodesDisplayWithIcon(name).join(', ');
  }
  getKeyCodeDisplayWithLabel(name: ShortcutKey<C>): string {
    const keyDef = this.getKeyDef(name);
    const keyCode = this.getKeyCodeDisplayWithIcon(name);
    const keyCodeDisplay = keyCode ? ` (${keyCode})` : '';
    return `${keyDef.label}${keyCodeDisplay}`;
  }
  getGithubHotkey(name: ShortcutKey<C>) {
    const prefs = this.getPreferences(name);
    return {
      label: prefs.map(p => this.formatter.getKeyCodeDisplayWithIcon(p)).join(', '),
      hotkey: prefs.map(p => this.formatter.getGithubHotkey(p)).join(','),
    };
  }
}

export const globalShortcutKeyProvider = new ShortcutKeyProvider(
  getShortcutKeyFormatter(),
  'Global',
);
export const notebookShortcutKeyProvider = new ShortcutKeyProvider(
  getShortcutKeyFormatter(),
  'Notebook',
);

export const getShortcutKeyProvider = <C extends ShortcutContext>(context: C) => {
  if (context === 'Global') {
    return globalShortcutKeyProvider;
  } else if (context === 'Notebook') {
    return notebookShortcutKeyProvider;
  } else {
    throw new Error('Invalid context');
  }
};

// reexports
export { getShortcutKeyFormatter, SHORTCUT_DEFS };
export type { ShortcutContext, ShortcutKey, GlobalShortcutKey, NotebookShortcutKey };
