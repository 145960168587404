import type { TrackerModule, IdentifyProps, IdentifyPropsWithCompany } from '../types';

export class CannyTracker implements TrackerModule {
  appID: string;

  constructor(appID: string) {
    this.appID = appID;
    this.insertCannyScript();
  }

  init(): void {
    // do nothing
  }

  identify(properties: IdentifyProps): void {
    if ((window as any).Canny) {
      (window as any).Canny('identify', {
        appID: this.appID,
        user: {
          email: properties.email,
          name: properties.name,
          id: properties.id,
        },
      });
    }
  }

  identifyWithCompany(properties: IdentifyPropsWithCompany): void {
    // TODO: implement
  }

  view(): void {
    // do nothing
  }

  track(event: string, properties?: any): void {
    // do nothing
  }

  openChat(): void {
    // do nothing
  }

  initChangelog(): void {
    if ((window as any).Canny) {
      (window as any).Canny('initChangelog', {
        appID: this.appID,
        position: 'top',
        align: 'left',
        theme: 'light',
      });
    }
  }

  public hasUnseenEntries(): boolean | undefined {
    return (window as any)?.Canny('hasUnseenEntries');
  }

  private insertCannyScript() {
    if (!document.getElementById('canny-jssdk')) {
      const script = document.createElement('script');
      script.id = 'canny-jssdk';
      script.type = 'text/javascript';
      script.async = true;
      script.src = 'https://canny.io/sdk.js';
      const s = document.getElementsByTagName('script')[0];
      if (!s.parentNode) return;
      s.parentNode.insertBefore(script, s);

      script.onload = () => {
        if (typeof (window as any).Canny !== 'function') {
          const c = (...args: any[]) => {
            (c.q as any).push(args);
          };
          // @ts-ignore
          c.q = [];
          (window as any).Canny = c;
        }
      };
    }
  }
}
