import { BaseClient } from "./common";
import type { 
  IssueTokenRequest,
  IssueTokenResponse,
  GetAccountRequest,
  GetAccountResponse,
  UploadAccountProfileImageRequest,
  UploadAccountProfileImageResponse,
  CheckGravatarImageExistanceRequest,
  CheckGravatarImageExistanceResponse,
  UploadAccountProfileImageFromGravatarRequest,
  CreateAccountRequest,
  CreateAccountResponse,
  DeleteAccountRequest,
  DeleteAccountResponse,
  CreateAndSendEmailVerificationRequest,
  CreateAndSendEmailVerificationResponse,
  VerifyEmailRequest,
  VerifyEmailResponse,
  CreateFirebaseUserByPasswordRequest,
  CreateFirebaseUserByPasswordResponse,
  VerifyTurnstileRequest,
  VerifyTurnstileResponse,
  ConfirmWorkspaceInvitationRequest,
  ConfirmWorkspaceInvitationResponse,
} from "./shared-types";

export * from "./shared-types";

export class ApiClient extends BaseClient {
  
  async auth_issueToken(requestData: IssueTokenRequest, query: {} = {} ): Promise<IssueTokenResponse> {
    return await this.fetch("/account/auth-api/auth/issueToken", requestData, query, {});
  }
  
  async account_get(requestData: GetAccountRequest, query: {} = {} ): Promise<GetAccountResponse> {
    return await this.fetch("/account/auth-api/account/get", requestData, query, {});
  }
  
  async account_uploadProfileImage(requestData: UploadAccountProfileImageRequest, query: {} = {} ): Promise<UploadAccountProfileImageResponse> {
    return await this.fetch("/account/auth-api/account/uploadProfileImage", requestData, query, {});
  }
  
  async account_checkGravatarImageExistance(requestData: CheckGravatarImageExistanceRequest, query: {} = {} ): Promise<CheckGravatarImageExistanceResponse> {
    return await this.fetch("/account/auth-api/account/checkGravatarImageExistance", requestData, query, {});
  }
  
  async account_uploadProfileImageFromGravatar(requestData: UploadAccountProfileImageFromGravatarRequest, query: {} = {} ): Promise<UploadAccountProfileImageResponse> {
    return await this.fetch("/account/auth-api/account/uploadProfileImageFromGravatar", requestData, query, {});
  }
  
  async account_create(requestData: CreateAccountRequest, query: {} = {} ): Promise<CreateAccountResponse> {
    return await this.fetch("/account/auth-api/account/create", requestData, query, {});
  }
  
  async account_delete(requestData: DeleteAccountRequest, query: {} = {} ): Promise<DeleteAccountResponse> {
    return await this.fetch("/account/auth-api/account/delete", requestData, query, {});
  }
  
  async signup_createAndSendEmailVerification(requestData: CreateAndSendEmailVerificationRequest, query: {} = {} ): Promise<CreateAndSendEmailVerificationResponse> {
    return await this.fetch("/account/auth-api/signup/createAndSendEmailVerification", requestData, query, {});
  }
  
  async signup_verifyEmail(requestData: VerifyEmailRequest, query: {} = {} ): Promise<VerifyEmailResponse> {
    return await this.fetch("/account/auth-api/signup/verifyEmail", requestData, query, {});
  }
  
  async signup_CreateFirebaseUserByPassword(requestData: CreateFirebaseUserByPasswordRequest, query: {} = {} ): Promise<CreateFirebaseUserByPasswordResponse> {
    return await this.fetch("/account/auth-api/signup/CreateFirebaseUserByPassword", requestData, query, {});
  }
  
  async auth_verifyTurnstile(requestData: VerifyTurnstileRequest, query: {} = {} ): Promise<VerifyTurnstileResponse> {
    return await this.fetch("/account/auth-api/auth/verifyTurnstile", requestData, query, {});
  }
  
  async wsinvitation_confirmWorkspaceInvitation(requestData: ConfirmWorkspaceInvitationRequest, query: {} = {} ): Promise<ConfirmWorkspaceInvitationResponse> {
    return await this.fetch("/account/auth-api/wsinvitation/confirmWorkspaceInvitation", requestData, query, {});
  }
  
}
