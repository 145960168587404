import { computed, inject, provide } from 'vue';
import type { InjectionKey, Ref } from 'vue';
import { useQueryClient } from '@tanstack/vue-query';
import type { Workspace } from '@cdm/@generated-account-client/client';
import AccountStore from '@cdm/domains/account/shared/stores/AccountStore';

export function useCurrentWorkspaceId() {
  const currentWorkspaceId = computed(() => AccountStore.getWorkspaceId() || '');
  return currentWorkspaceId;
}

const WorkspaceSymbol = Symbol() as InjectionKey<Ref<Workspace>>;

export function useProvideWorkspace(workspace: Ref<Workspace>) {
  provide(WorkspaceSymbol, workspace);
}

export function useCurrentWorkspace() {
  const workspace = inject(WorkspaceSymbol);
  if (!workspace) {
    throw new Error('No workspace provided');
  }

  return {
    workspace,
  };
}

export function useInvalidateWorkspace() {
  const queryClient = useQueryClient();

  function invalidateWorkspace() {
    queryClient.invalidateQueries({ queryKey: ['account', 'workspace', 'get'] });
  }

  return {
    invalidateWorkspace,
  };
}
