import type { CommentBody } from '../types/comment-thread';

export const COMMENT_THREAD_TARGET_MODELS = {
  NOTEBOOK: 'Notebook',
  REPORT: 'Report',
} as const;

export const COMMENT_THREAD_STATES = {
  ACTIVE: 'ACTIVE',
  RESOLVED: 'RESOLVED',
  OUTDATED: 'OUTDATED',
} as const;

export const COMMENT_NODE_NAMES = {
  DOC: 'doc',
  TEXT: 'text',
  PARAGRAPH: 'paragraph',
  BLOCKQUOTE: 'blockquote',
  MENTION: 'mention',
  IMAGE: 'image',
} as const;

export const COMMENT_INLINE_NODE_NAMES = [
  COMMENT_NODE_NAMES.TEXT,
  COMMENT_NODE_NAMES.MENTION,
] as const;

export const COMMENT_MARK_NAMES = {
  LINK: 'link',
  BOLD: 'bold',
  CODE: 'code',
  ITALIC: 'italic',
  STRIKE: 'strike',
  UNDERLINE: 'underline',
  HIGHLIGHT: 'highlight',
} as const;

export const COMMENT_TEXT_MARK_NAMES = [
  COMMENT_MARK_NAMES.BOLD,
  COMMENT_MARK_NAMES.CODE,
  COMMENT_MARK_NAMES.HIGHLIGHT,
  COMMENT_MARK_NAMES.ITALIC,
  COMMENT_MARK_NAMES.LINK,
  COMMENT_MARK_NAMES.STRIKE,
  COMMENT_MARK_NAMES.UNDERLINE,
] as const;

export const COMMENT_DEFAULT_VALUE = {
  type: 'doc',
  content: [{ type: 'paragraph' }],
} as CommentBody;

// 生成する SignedURL の expire までの秒数
export const COMMENT_THREAD_MEDIA_SIGNED_URL_TTL = 60 * 10;
