import resources from './bqFunctions.json';

export type BigQueryFunctionReference = {
  cat: string;
  hash: string;
  def?: string[];
  desc?: string[];
  ret?: string[];
};

type Categories = {
  [key: string]: string;
};

type Functions = {
  [functionName: string]: BigQueryFunctionReference;
};

class Store {
  public isValidFunctionName(name: string | null | undefined): boolean {
    if (!name) return false;
    return Object.keys(resources.functions).includes(name.toUpperCase());
  }
  public getBqFunctionCategories(): { label: string; value: string }[] {
    const cats: Categories = resources.categories;
    return Object.keys(cats).map(key => ({
      label: cats[key],
      value: key,
    }));
  }
  public getBqFunctionList(): { name: string; cat: string }[] {
    const funcs: Functions = resources.functions;
    const cats: Categories = resources.categories;
    return Object.keys(funcs).map(key => ({
      name: key,
      cat: cats[funcs[key]?.cat],
    }));
  }
  public getCategoryNameByBqFunctionName(name: string): string {
    const funcs: Functions = resources.functions;
    const cats: Categories = resources.categories;
    const func = funcs[name.toUpperCase()];
    if (!func) return '';
    return cats[func.cat] || '';
  }
  public getBqFunctionNames(): string[] {
    const funcs: Functions = resources.functions;
    return Object.keys(funcs);
  }
  public getBqFunctionNamesByCategory(key: string | null): string[] {
    if (!key) return [];
    const funcs: Functions = resources.functions;
    return Object.keys(funcs).filter(funcName => {
      return funcs[funcName]?.cat === key;
    });
  }
  public getBqFunctionReferenceHtml(
    name: string | null | undefined,
    options: { className: string },
  ): string | null {
    if (!name) return null;
    const funcs: Functions = resources.functions;
    const func = funcs[name.toUpperCase()] || null;
    if (!func) return null;
    const cats: Categories = resources.categories;
    const link = `https://cloud.google.com/bigquery/docs/reference/standard-sql/${func.cat}#${func.hash}`;
    let html = '';
    html += `<div class="ReferenceHeader">
      <h1>${name.toUpperCase()} <small>${cats[func.cat] || ''}</small></h1>
      <a href="${link}" target="_blank" rel="noopener noreferrer" class="ReferenceHeader_Link">
        <svg class="svg-inline--fa fa-arrow-up-right-from-square" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-up-right-from-square" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-v-02060250=""><path class="" fill="currentColor" d="M320 0c-17.7 0-32 14.3-32 32s14.3 32 32 32h82.7L201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L448 109.3V192c0 17.7 14.3 32 32 32s32-14.3 32-32V32c0-17.7-14.3-32-32-32H320zM80 32C35.8 32 0 67.8 0 112V432c0 44.2 35.8 80 80 80H400c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32V432c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16H192c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z"></path></svg>
      </a>
    </div>`;
    if (func.def && func.def.length > 0) {
      html += func.def.join('');
    }
    if (func.desc && func.desc.length > 0) {
      html += `<div class="bold">Description</div>`;
      html += func.desc.join('');
    }
    if (func.ret && func.ret.length > 0) {
      html += `<div class="bold">Return value</div>`;
      html += func.ret.join('');
    }
    // style の注入は className 指定で別途 vue から行う
    return `<div class="${options.className}">${html}</div>`;
  }
}

export default new Store();
