import { Node, Extension, mergeAttributes } from '@tiptap/core';
import { History } from '@tiptap/extension-history';
import { createSyntaxHighlightPlugin } from './Plugins';
import { getCodeEditorKeyboardShortcutBindings } from '@cdm/libs/tiptap-keyboard-shortcuts';

const Document = Node.create({
  name: 'doc',
  topNode: true,
  content: 'code',
});

const Code = Node.create({
  name: 'code',
  content: 'text*',
  code: true,
  defining: true,
  parseHTML() {
    return [
      {
        tag: 'pre',
        preserveWhitespace: 'full',
      },
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return [
      'pre',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      ['code', { spellcheck: false }, 0],
    ];
  },
  addKeyboardShortcuts() {
    return getCodeEditorKeyboardShortcutBindings();
  },
  addProseMirrorPlugins() {
    // とりあえず最低限の syntaxHighlight だけ付与
    // TODO: 関数説明とか入力補完とかも追加したい
    return [createSyntaxHighlightPlugin('code')];
  },
});

const Text = Node.create({
  name: 'text',
});

export const getNodes = (): (Node | Extension)[] => {
  return [Document, Code, Text, History];
};

type SqlEditorDocument = {
  type: 'doc';
  content: {
    type: 'code';
    content: {
      type: 'text';
      text: string;
    }[];
  }[];
};

export const textToDoc = (txt: string): SqlEditorDocument => {
  const doc: SqlEditorDocument = {
    type: 'doc',
    content: [{ type: 'code', content: [] }],
  };
  if (txt) {
    doc.content[0].content.push({ type: 'text', text: txt });
  }
  return doc;
};

export const docToText = (doc: SqlEditorDocument): string => {
  return doc.content[0]?.content?.map(t => t.text).join('') || '';
};
