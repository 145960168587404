import { NODE_NAMES } from '@cdm/@shared-server-notebook/constants/notebook';
import moo from 'moo';
import type { SqlBlockBodyContent, NodeJson } from '@cdm/@shared-server-notebook/types/notebook';
import type { AssistantMessageSqlCodeBlockContent } from '@cdm/@shared-server-notebook/types/my-assistant';
import { ASSISTANT_MESSAGE_CODE_BLOCK_CONTENTS } from '@cdm/@shared-server-notebook/constants/my-assistant';
import { isValidBqFunctionName, BQ_RESERVED_KEYWORDS } from '@cdm/libs/sql-formatter';

export const SYNTAX_HIGHLIGHT_TOKEN_TYPES = {
  COMMENT: 'COMMENT',
  WHITESPACE: 'WHITESPACE',
  LPAREN: 'LPAREN',
  RPAREN: 'RPAREN',
  LBRACKET: 'LBRACKET',
  RBRACKET: 'RBRACKET',
  RBRACE: 'RBRACE',
  LBRACE: 'LBRACE',
  SEMICOLON: 'SEMICOLON',
  COMMA: 'COMMA',
  LITERAL: 'LITERAL',
  OPERATOR: 'OPERATOR',
  FUNCTION: 'FUNCTION',
  WORD: 'WORD',
  KEYWORD: 'KEYWORD',
  REFERENCE: 'REFERENCE',
  OTHER: 'OTHER',
} as const;

export type SyntaxHighlightTokenType =
  typeof SYNTAX_HIGHLIGHT_TOKEN_TYPES[keyof typeof SYNTAX_HIGHLIGHT_TOKEN_TYPES];

type SyntaxHighlightTokenizeRules = {
  [token in SyntaxHighlightTokenType]?:
    | RegExp
    | RegExp[]
    | string
    | string[]
    | moo.Rule
    | moo.Rule[]
    | moo.ErrorRule
    | moo.FallbackRule;
};

const RULES: SyntaxHighlightTokenizeRules = {
  // 適当なので順次足していく
  COMMENT: [/--[^\n]*/, /#[^\n]*/, /\/\*[^]*?\*\//],
  WHITESPACE: { match: /\s+/, lineBreaks: true },
  LPAREN: '(', // Left Parenthesis
  RPAREN: ')', // Right ...
  LBRACKET: '[', // Left (square) Bracket
  RBRACKET: ']', // Right ...
  LBRACE: '{', // Left Brace
  RBRACE: '{', // Right ...
  SEMICOLON: ';',
  COMMA: ',',
  LITERAL: [
    // https://cloud.google.com/bigquery/docs/reference/standard-sql/lexical#literals
    /(?:r|R|b|B){0,2}"""[^]*?"""/,
    /(?:r|R|b|B){0,2}'''[^]*?'''/,
    /(?:r|R|b|B){0,2}"(?:[^"\\]|\\.)*"/, // \" を加味した " で囲われたSTRING https://stackoverflow.com/a/249937
    /(?:r|R|b|B){0,2}'(?:[^'\\]|\\.)*'/, // 同上,
    /`(?:[^`\\]|\\.)*`/, // 同上,
    /[-+0]?[0-9.]+/,
  ],
  OPERATOR: [
    // https://cloud.google.com/bigquery/docs/reference/standard-sql/operators
    '+',
    '-',
    '~',
    '*',
    '/',
    '||',
    '<<',
    '>>',
    '&',
    '^',
    '|',
    '=',
    '<',
    '>',
    '<=',
    '>=',
    '!=',
    '<>',
  ],
  WORD: {
    match: /[\w]+/,
    type: moo.keywords({
      KEYWORD: BQ_RESERVED_KEYWORDS.map(kw => kw.toLowerCase()),
    }),
  },
  OTHER: /[^\s]+/, // その他すべてにマッチ
};

export interface SyntaxHighlightToken {
  type: SyntaxHighlightTokenType;
  size: number;
  text?: string;
  rangeTo?: number;
  rangeFrom?: number;
  argsOf?: number;
  maybeTable?: boolean;
}

export const tokenizeSqlBlockForSyntaxHighlight = (
  contents: NonNullable<SqlBlockBodyContent>,
): SyntaxHighlightToken[] => {
  const tokens: SyntaxHighlightToken[] = [];
  const lexer = moo.compile(RULES as moo.Rules);
  for (const c of contents) {
    if (c.type === 'text') {
      lexer.reset(c.text!.toLowerCase());
      for (const token of lexer) {
        tokens.push({
          type: token.type as SyntaxHighlightTokenType,
          size: token.text.length,
          text: token.text,
        });
      }
    } else if (c.type === NODE_NAMES.SQL_BLOCK_REF) {
      tokens.push({ type: SYNTAX_HIGHLIGHT_TOKEN_TYPES.REFERENCE, size: 1 });
    } else if (c.type === NODE_NAMES.SQL_TABLE_REF) {
      tokens.push({ type: SYNTAX_HIGHLIGHT_TOKEN_TYPES.REFERENCE, size: 1 });
    } else if (c.type === NODE_NAMES.SQL_QUERY_REF) {
      tokens.push({ type: SYNTAX_HIGHLIGHT_TOKEN_TYPES.REFERENCE, size: 1 });
    } else if (c.type === NODE_NAMES.SQL_PARAM_REF) {
      tokens.push({ type: SYNTAX_HIGHLIGHT_TOKEN_TYPES.REFERENCE, size: 1 });
    } else {
      // @ts-expect-error 型上はありえないが…
      throw new Error(`unexpected content type: ${c.type}`);
    }
  }
  _applyTransformTokens(tokens);
  return tokens;
};

export const tokenizeSqlCodeBlockForSyntaxHighlight = (
  contents: NonNullable<AssistantMessageSqlCodeBlockContent>,
): SyntaxHighlightToken[] => {
  const tokens: SyntaxHighlightToken[] = [];
  const lexer = moo.compile(RULES as moo.Rules);
  for (const c of contents) {
    if (c.type === 'text') {
      lexer.reset(c.text!.toLowerCase());
      for (const token of lexer) {
        tokens.push({
          type: token.type as SyntaxHighlightTokenType,
          size: token.text.length,
          text: token.text,
        });
      }
    } else if (ASSISTANT_MESSAGE_CODE_BLOCK_CONTENTS.includes(c.type)) {
      tokens.push({ type: SYNTAX_HIGHLIGHT_TOKEN_TYPES.REFERENCE, size: 1 });
    } else {
      throw new Error(`unexpected content type: ${c.type}`);
    }
  }
  _applyTransformTokens(tokens);
  return tokens;
};

export const tokenizeSqlStringForSyntaxHighlight = (txt: string): SyntaxHighlightToken[] => {
  const contents = [
    {
      type: 'text',
      text: txt,
    } as NodeJson<typeof NODE_NAMES.TEXT>,
  ];
  return tokenizeSqlBlockForSyntaxHighlight(contents);
};

const _applyTransformTokens = (tokens: SyntaxHighlightToken[]): void => {
  // WORD の次に ( が出てきた場合に関数と判定
  const tokensWithoutWs = tokens.filter(t => t.type !== SYNTAX_HIGHLIGHT_TOKEN_TYPES.WHITESPACE);
  for (const [i, t] of tokensWithoutWs.entries()) {
    if (t.type === SYNTAX_HIGHLIGHT_TOKEN_TYPES.WORD) {
      if (
        i < tokensWithoutWs.length - 1 &&
        tokensWithoutWs[i + 1].type === SYNTAX_HIGHLIGHT_TOKEN_TYPES.LPAREN
      ) {
        t.type = SYNTAX_HIGHLIGHT_TOKEN_TYPES.FUNCTION;
      }
    }
  }
  // 括弧の対応を設定
  [
    [SYNTAX_HIGHLIGHT_TOKEN_TYPES.LPAREN, SYNTAX_HIGHLIGHT_TOKEN_TYPES.RPAREN],
    [SYNTAX_HIGHLIGHT_TOKEN_TYPES.LBRACKET, SYNTAX_HIGHLIGHT_TOKEN_TYPES.RBRACKET],
    [SYNTAX_HIGHLIGHT_TOKEN_TYPES.LBRACE, SYNTAX_HIGHLIGHT_TOKEN_TYPES.RBRACE],
  ].forEach(([fromToken, toToken]) => {
    const stack: number[] = [];
    for (const [i, t] of tokens.entries()) {
      if (t.type === fromToken) {
        stack.push(i);
      } else if (t.type === toToken) {
        const rangeFrom = stack.pop();
        if (rangeFrom) {
          t.rangeFrom = rangeFrom;
          tokens[rangeFrom].rangeTo = i;
          // ()に対応する関数の定義があれば後で表示用に argsOf に入れておく
          if (
            fromToken === SYNTAX_HIGHLIGHT_TOKEN_TYPES.LPAREN &&
            rangeFrom > 0 &&
            tokens[rangeFrom - 1].type === SYNTAX_HIGHLIGHT_TOKEN_TYPES.FUNCTION &&
            tokens[rangeFrom - 1].text &&
            isValidBqFunctionName(tokens[rangeFrom - 1].text)
          ) {
            t.argsOf = rangeFrom - 1;
            tokens[rangeFrom].argsOf = rangeFrom - 1;
          }
        }
      }
    }
  });
  // テーブルっぽいものの判定
  for (const [i, t] of tokensWithoutWs.entries()) {
    if (t.type === SYNTAX_HIGHLIGHT_TOKEN_TYPES.LITERAL) {
      if (t.text && parseFullTableId(t.text)) {
        t.maybeTable = true;
      }
    }
  }
};

export const parseFullTableId = (
  fullTableId: string,
): { projectId: string; datasetId: string; tableId: string } | null => {
  // TODO: 日本語のテーブル名とかに対応する？
  const matched = fullTableId.match(/^`([a-zA-Z0-9-_]+)\.([a-zA-Z0-9-_]+)\.([a-zA-Z0-9-_]+[*]?)`$/);
  if (!matched) return null;
  const [, projectId, datasetId, tableId] = matched;
  return { projectId, datasetId, tableId };
};
