export const getColorFromCssVariable = (
  name: string,
  options: { toHex?: boolean } = {},
): string => {
  const value = getComputedStyle(document.body).getPropertyValue(name)?.trim() ?? '';
  if (value.toLowerCase().match(/^#[0-9a-f]+$/)) {
    // 最初から #ffffff みたいな場合
    return value;
  }
  const matched = [...value.matchAll(/([0-9.]+)/g)];
  if (!options.toHex) {
    if (value.toLowerCase().startsWith('rgb')) {
      return value;
    } else {
      // arco の場合、単純に xxx, yyy, zzz という数字だけが入っている場合がある…
      if (matched.length === 3) {
        return `rgb(${value})`;
      } else if (matched.length === 4) {
        return `rgba(${value})`;
      }
    }
  } else {
    const hex = matched
      .slice(0, 3)
      .map(m => m[0])
      .map(v => `0${Number(v).toString(16)}`.slice(-2))
      .join('');
    if (matched.length === 3) {
      return `#${hex}`;
    } else if (matched.length === 4) {
      const alpha = Math.floor(Number(matched[3][0]) * 255)
        .toString(16)
        .padStart(2, '0');
      return `#${hex}${alpha}`;
    }
  }
  console.error('failed to getColorFromCssVariable', { name, value });
  return '';
};
