import type { Editor } from '@tiptap/core';
import type { KeyboardShortcutBindings, KeyboardShortcutCommand } from './utils';
import type { NotebookShortcutKey } from '@cdm/utils/keyboardShortcut';
import { notebookShortcutKeyProvider } from '@cdm/utils/keyboardShortcut';

export const SHORTCUT_KEYS = notebookShortcutKeyProvider.KEYS;
export const keyboardShortcutProvider = notebookShortcutKeyProvider;
export const getShortcut = keyboardShortcutProvider.getPreferences.bind(keyboardShortcutProvider);
export const generateShortcutListData =
  keyboardShortcutProvider.getShortcutListData.bind(keyboardShortcutProvider);
export const getShortcutKeyCodeDisplay =
  keyboardShortcutProvider.getKeyCodeDisplay.bind(keyboardShortcutProvider);
export const getGithubHotkey =
  keyboardShortcutProvider.getGithubHotkey.bind(keyboardShortcutProvider);
export const getShortcutLabelDisplay =
  keyboardShortcutProvider.getKeyCodeDisplayWithLabel.bind(keyboardShortcutProvider);
export const getKeyCodeDisplayWithIcon =
  keyboardShortcutProvider.getKeyCodeDisplayWithIcon.bind(keyboardShortcutProvider);

export const registerShortcuts = <E extends Editor>(
  shortcuts: KeyboardShortcutBindings<E>,
  name: NotebookShortcutKey,
  func: KeyboardShortcutCommand<E>,
) => {
  const keyCodes = keyboardShortcutProvider.getKeyCodes.bind(keyboardShortcutProvider)(name);
  keyCodes.forEach(key => {
    shortcuts[key] = func;
  });
  return shortcuts;
};
