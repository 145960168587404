import { ref, computed } from 'vue';
import type {
  KeyboardShortcutPreferences,
  ShortcutPreference,
} from '@cdm/@generated-account-client/shared-types';
import { cloneDeep, isEqual } from 'lodash-es';
import { accountClient } from '@cdm/clients/AccountClient';
import { Message } from '@cdm/components/arco/Message';
import AccountStore from '@cdm/domains/account/shared/stores/AccountStore';
import type { ShortcutContext, ShortcutKey } from '@cdm/utils/keyboardShortcut';
import { isShortcutExists } from '@cdm/utils/keyboardShortcut/util';

// FIXME: This is a temporary solution to handle global recording state
const recordingContext = ref('');
const recordingName = ref('');

export const useKeyboardShortcutSettings = () => {
  const preferences = computed(() => AccountStore.setting.keyboardShortcuts);

  const setRecording = (context: string, name: string) => {
    recordingContext.value = context;
    recordingName.value = name;
  };
  const clearRecording = () => {
    recordingContext.value = '';
    recordingName.value = '';
  };
  const isRecording = computed(() => recordingContext.value !== '' && recordingName.value !== '');
  const isShortcutRecording = (context: string, name: string) => {
    return recordingContext.value === context && recordingName.value === name;
  };

  const _isSaving = ref(false);
  const isSaving = computed(() => _isSaving.value);

  const getShortcut = <C extends ShortcutContext>(context: C, name: ShortcutKey<C>) => {
    return preferences.value?.[context]?.[name] as ShortcutPreference[] | undefined;
  };

  const _save = async (shortcuts: KeyboardShortcutPreferences | null) => {
    if (_isSaving.value) return;
    try {
      _isSaving.value = true;

      await accountClient.accountSetting_updateKeyboardShortcuts({
        keyboard_shortcuts: shortcuts,
      });
      await AccountStore.setting.fetchSetting();
      Message.success('Shortcut saved');
      return true;
    } catch (err) {
      Message.error('Failed to save preferences');
      return false;
    } finally {
      _isSaving.value = false;
    }
  };

  const saveContext = async (
    context: ShortcutContext,
    preferences: KeyboardShortcutPreferences,
  ) => {
    const keyboardShortcuts = {
      [context]: preferences,
    };
    return await _save(keyboardShortcuts);
  };

  const save = async (
    context: ShortcutContext,
    key: ShortcutKey<ShortcutContext>,
    shortcut: ShortcutPreference,
  ) => {
    if (_isSaving.value) return;

    const prefs = preferences.value?.[context];
    // const conflicts = findConflicts(preferences.value || {}, context, key, shortcut);
    // if (conflicts.length > 0) {
    //   const conflictsInfo = conflicts.map(c => `${c.context} > ${c.keyDef.label}`).join(', ');
    //   Message.info(`Conflict with "${conflictsInfo}"`);
    //   return;
    // }
    if (!prefs) {
      return await saveContext(context, { [key as string]: [shortcut] });
    }
    const shortcuts = prefs[key] || [];
    if (isShortcutExists(shortcuts, shortcut)) {
      Message.info('Already set');
      return;
    } else {
      shortcuts.push(shortcut);
    }
    prefs[key] = shortcuts;
    return await saveContext(context, prefs);
  };
  const reset = async (context: ShortcutContext, key: string) => {
    if (_isSaving.value) return;
    const prefs = cloneDeep(preferences.value?.[context]);
    if (!prefs?.[key]) return;
    delete prefs[key];
    return await saveContext(context, prefs);
  };
  const unset = async (context: ShortcutContext, key: string) => {
    if (_isSaving.value) return;
    const prefs = preferences.value?.[context] || {};
    const newPrefs = cloneDeep(prefs);
    newPrefs[key] = [];
    return await saveContext(context, newPrefs);
  };
  const remove = async (context: ShortcutContext, key: string, preference: ShortcutPreference) => {
    if (_isSaving.value) return;
    const prefs = preferences.value?.[context];
    if (!prefs || !prefs[key]) {
      return await unset(context, key);
    }
    const shortcuts = prefs[key] || [];
    if (!isShortcutExists(shortcuts, preference)) return;
    const newPrefs = cloneDeep(prefs);
    newPrefs[key] = shortcuts.filter((p: ShortcutPreference) => !isEqual(p, preference));
    return await saveContext(context, newPrefs);
  };
  const resetAll = async () => {
    if (_isSaving.value) return;
    return await _save(null);
  };
  return {
    preferences,
    getShortcut,
    setRecording,
    clearRecording,
    isRecording,
    isShortcutRecording,
    isSaving,
    save,
    reset,
    remove,
    unset,
    resetAll,
  };
};
